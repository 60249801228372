import axios from "axios";
import { store } from "../store";
import { setToken } from "../store/actions";
import * as apiService from "./apiService";
import * as userModel from "../models/userModel";
import * as tokenModel from "../models/tokenModel";
import moment from "moment";
import ssoMicrosoftService from "./ssoMicrosoftService";

export async function getUserByEmailOrCpfOrInternalCode(
  eventId,
  configList,
  value,
  isAdmin,
  isDeveloper,
  isStaff,
  password,
  tokenLogin,
  tokenRecaptcha
) {
  var method = apiService.getUrl().firebase + "hallauthenticationv3";
  let _configList = configList.filter((c) => {
    return c.screenId === 4 || c.screenId === 9;
  });

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        eventId: parseInt(eventId),
        eventName: store.getState().event.name,
        value: value,
        configList: _configList,
        path: window.location.href,
        password: password,
        tokenLogin: tokenLogin,
        language: store.getState().lang.lang,
        tokenRecaptcha: tokenRecaptcha,
      },
    };
    axios(options)
      .then(async (res) => {
        if (res.data.success && res.data.message === "Token enviado") {
          resolve({ user: null, isTokenEmail: true });
          return;
        }

        if (res.data.success) {
          let _token = await ssoMicrosoftService.createToken(res.data.data.id.toString(), res.data.data.email);

          const user = userModel.createUserModel(
            res.data.data.id,
            parseInt(eventId),
            res.data.data.name,
            res.data.data.email ? res.data.data.email : "",
            res.data.data.institution ? res.data.data.institution : "",
            res.data.data.avatarProfileLink
              ? res.data.data.avatarProfileLink
              : "",
            res.data.data.hallFirstTerm,
            res.data.data.hallSecondTerm,
            isAdmin,
            isDeveloper,
            isStaff,
            res.data.data.groupId,
            res.data.data.internalCode,
            res.data.data.registration || "",
            null,
            res.data.data.company,
            res.data.data.token,
            res.data.data.visibleToChat,
            res.data.data.ranking && res.data.data.ranking.levelId
              ? res.data.data.ranking.levelId
              : null,
            res.data.data.ranking &&
              res.data.data.ranking.level &&
              res.data.data.ranking.level.name
              ? res.data.data.ranking.level.name
              : null,
            res.data.data.ranking && res.data.data.ranking.points
              ? res.data.data.ranking.points
              : null,
            res.data.data.specialty,
            res.data.data.ranking && res.data.data.ranking.position
              ? res.data.data.ranking.position
              : null,
            _token,
          );

          let _userToken = tokenModel.createTokenModel(
            res.data.data.accessToken || "",
            moment().format()
          );
          store.dispatch(setToken(_userToken));
          resolve({ user: user, isTokenEmail: false });
        } else {
          if ((res.data.message = "Email inválido")) {
            reject(new Error("Email inválido"));
            return;
          }
          const user = userModel.createUserModel();
          resolve({ user: user, isTokenEmail: false });
        }
      })
      .catch((err) => {
        console.log("Erro getEvent", err);
        reject(err);
      });
  });
}

export async function getUserFromParticipantBankByCpf(cpf, eventId) {
  var method =
    apiService.getUrl().hall +
    "event/possibleGuest?cpf=" +
    cpf.toString() +
    "&eventId=" +
    eventId.toString();
  const token = await apiService.getToken();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data && res.data.data) {
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getUserFromParticipantBankByCpf", err);
        reject(err);
      });
  });
}

export async function changeUserTerms(firstTerm, secondTerm) {
  var method = apiService.getUrl().hall + "event/guest/termsofservice";
  const token = await apiService.getToken();
  var user = store.getState().user;

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: {
        guestId: user.id,
        firstTerm: firstTerm,
        secondTerm: secondTerm,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data.success) {
          resolve(true);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getEvent", err);
        reject(err);
      });
  });
}

export async function addNewUserApi(
  attributesList,
  attributesValueList,
  configList,
  tokenRecaptcha,
  password
) {
  var method = apiService.getUrl().firebase + "hallregisteruserv3";
  const event = store.getState().event;
  let _configList = configList.filter((c) => {
    return c.screenId === 4 || c.screenId === 9;
  });

  return new Promise((resolve, reject) => {
    const _attributeName = attributesValueList.find((a) => {
      return a.dbReference === "Nome";
    });
    if (!_attributeName) {
      reject("Form não possui Nome");
      return;
    }

    const options = {
      url: method,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        eventId: event.id,
        contractId: event.contractId,
        eventName: event.name,
        value: _attributeName.value.replace(" ", ""),
        attributesList: attributesList,
        attributesValueList: attributesValueList,
        configList: _configList,
        path: window.location.href,
        language: store.getState().lang.lang,
        tokenRecaptcha: tokenRecaptcha,
        newPassword: password,
      },
    };
    axios(options)
      .then((res) => {
        if (res && res.data && res.data.success) {
          let _return = {
            user: null,
            isTwoFactosLogin: false,
          };

          if (res.data.message === "ENABLE-TWO-FACTOR-LOGIN") {
            _return.isTwoFactosLogin = true;
            resolve(_return);
            return;
          }

          let guest = res.data.id ? res.data : res.data.data;

          var user = userModel.createUserModel(
            guest.id,
            event.id,
            guest.name,
            guest.email,
            guest.institution ? guest.institution : "",
            guest.avatarProfileLink ? guest.avatarProfileLink : "",
            guest.hallFirstTerm,
            guest.hallSecondTerm,
            null,
            null,
            null,
            null,
            guest.registration,
            guest.registration,
            guest.password,
            guest.company,
            guest.visibleToChat,
            guest.ranking && guest.ranking.levelId
              ? guest.ranking.levelId
              : null,
            guest.ranking && guest.ranking.level && guest.ranking.level.name
              ? guest.ranking.level.name
              : null,
            guest.ranking && guest.ranking.points ? guest.ranking.points : null,
            guest.specialty,
            guest.ranking && guest.ranking.position
              ? guest.ranking.position
              : null
          );

          if (user.name === "" || !user.name) {
            user.name = guest.id.toString();
          }
          if (user.email === "" || !user.email) {
            user.email = guest.id.toString();
          }

          let _userToken = tokenModel.createTokenModel(
            guest.accessToken || "",
            moment().format()
          );
          store.dispatch(setToken(_userToken));

          _return.user = user;
          resolve(_return);
        } else {
          let _message = "Não foi possível realizar o cadastro";
          if ((res.data.message = "Email inválido")) {
            _message = res.data.message;
          }
          reject(_message);
        }
      })
      .catch((err) => {
        console.log("Erro addNewUserApi", err, options);
        reject(err);
      });
  });
}

export async function updateUserApi(user, attributesValueList, isDeveloper) {
  try {
    let guest = await updateGuest(user.id, attributesValueList);

    var user = userModel.createUserModel(
      guest.id,
      store.getState().event.id,
      guest.name ? guest.name : "",
      guest.email ? guest.email : "",
      guest.institution ? guest.institution : "",
      guest.avatarProfileLink ? guest.avatarProfileLink : "",
      guest.hallFirstTerm,
      guest.hallSecondTerm,
      null,
      isDeveloper,
      null,
      null,
      guest.internalCode,
      guest.internalCode,
      guest.password ? guest.password : null,
      guest.company,
      guest.visibleToChat,
      guest.ranking && guest.ranking.levelId
        ? guest.ranking.levelId
        : null,
      guest.ranking && guest.ranking.level && guest.ranking.level.name
        ? guest.ranking.level.name
        : null,
      guest.ranking && guest.ranking.points ? guest.ranking.points : null,
      guest.specialty,
      guest.ranking && guest.ranking.position
        ? guest.ranking.position
        : null,
      null,
      guest.securityToken,
    );

    if (user.name === "" || !user.name) {
      user.name = guest.id.toString();
    }
    if (user.email === "" || !user.email) {
      user.email = guest.id.toString();
    }

    return user;

  } catch (error) {
    console.log('Error updateUserApi', error);
    throw error;
  }

  async function updateGuest(userId = '', attributesList = []) {
    try {
      const options = {
        url: `${apiService.getUrl().hallAuthenticationApi}guest/updateUserApi`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          token: store.getState().user.securityToken,
          userId: userId,
          attributesList: attributesList,
        },
      };

      let _res = await axios(options);

      let _user = _res.data.data;

      return _user;
    } catch (error) {
      console.log("Error updateGuest", error);
      throw error;
    }
  };
}

export async function setNewUserPasswordApi(
  userId,
  userEmail,
  userPassword,
  userToken,
  isDeveloper,
  isForgotPassword = false
) {
  var method = apiService.getUrl().hall + "event/guest/password";
  const token = await apiService.getToken();
  const event = store.getState().event;

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: {
        guestToken: userToken,
        email: userEmail,
        password: userPassword,
        isForgotPassword: isForgotPassword,
        // guestId: parseInt(userId)
      },
    };
    axios(options)
      .then((res) => {
        if (res.data.success && res.data.data && res.data.data.id) {
          // O retorno deve ser igual ao getUser
          const guest = res.data.data;

          var user = userModel.createUserModel(
            guest.id,
            event.id,
            guest.name ? guest.name : "",
            guest.email ? guest.email : "",
            guest.institution ? guest.institution : "",
            guest.avatarProfileLink ? guest.avatarProfileLink : "",
            guest.hallFirstTerm,
            guest.hallSecondTerm,
            null,
            isDeveloper,
            null,
            guest.groupId,
            guest.registration,
            guest.registration,
            guest.password,
            guest.company,
            guest.visibleToChat,
            guest.ranking && guest.ranking.levelId
              ? guest.ranking.levelId
              : null,
            guest.ranking && guest.ranking.level && guest.ranking.level.name
              ? guest.ranking.level.name
              : null,
            guest.ranking && guest.ranking.points ? guest.ranking.points : null,
            guest.specialty,
            guest.ranking && guest.ranking.position
              ? guest.ranking.position
              : null
          );

          resolve(user);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro setNewUserPasswordApi", err);
        reject(err);
      });
  });
}

export async function setUserChatVisibility(chatVisibility) {
  var method = apiService.getUrl().hall + "event/guest/chat-visibility";
  const token = await apiService.getToken();
  const user = store.getState().user;

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      data: {
        guestId: user.id,
        isVisibleToChat: chatVisibility,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data.success) {
          resolve(true);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro setUserChatVisibility", err);
        reject(err);
      });
  });
}

/*
  Get all users registered in this event ID
*/
export async function getTotalRegisteredUsers(eventId) {
  var method =
    apiService.getUrl().hall + "event/guest/quantity/" + eventId.toString();
  const token = await apiService.getToken();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        console.log("Erro getTotalRegisteredUsers", err);
        reject(err);
      });
  });
}

export async function validateCrmApi(type = "CRM", value = "", state = "") {
  var method =
    apiService.getUrl().validateCrm +
    `ConsultaCrm?tipo=${type}&query=${value}&uf=${state}`;
  const token = await apiService.getToken();

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    axios(options)
      .then((res) => {
        if (
          res.data &&
          res.data.total &&
          res.data.total > 0 &&
          res.data.item &&
          res.data.item.length > 0 &&
          res.data.item[0].situacao &&
          (res.data.item[0].situacao === "Ativo" ||
            res.data.item[0].situacao === "Regular")
        ) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        console.log("Erro validateCrm", err);
        reject(err);
      });
  });
}

export async function saveUserAccess(userId, eventId, token) {
  var method = `${apiService.getUrl().firebase}saveuseraccessv2`;

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      data: {
        userId: userId,
        eventId: eventId,
        token: token,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data.success) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        console.log("Erro save user token", err);
        reject(err);
      });
  });
}

export async function setForgotPasswordRoutine(
  eventId,
  eventName,
  value,
  token,
  newPassword1,
  newPassword2
) {
  var method = `${apiService.getUrl().firebase}hallforgotpasswordv3`;
  let _configList = store.getState().config.filter((c) => {
    return c.screenId === 4 || c.screenId === 9;
  });

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      data: {
        eventId: eventId,
        eventName: eventName,
        value: value,
        configList: _configList,
        token: token || "",
        newPassword1: newPassword1 || "",
        newPassword2: newPassword2 || "",
        language: store.getState().lang.lang,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data.success) {
          let _return = {
            user: null,
            emailSent: false,
          };

          if (res.data.message === "Email enviado") {
            _return.emailSent = true;
            return resolve(_return);
          }

          const user = userModel.createUserModel(
            res.data.data.id,
            parseInt(eventId),
            res.data.data.name,
            res.data.data.email ? res.data.data.email : "",
            res.data.data.institution ? res.data.data.institution : "",
            res.data.data.avatarProfileLink
              ? res.data.data.avatarProfileLink
              : "",
            res.data.data.hallFirstTerm,
            res.data.data.hallSecondTerm,
            null,
            null,
            null,
            res.data.data.groupId,
            res.data.data.registration,
            res.data.data.registration,
            null,
            res.data.data.company,
            res.data.data.token,
            res.data.data.visibleToChat,
            res.data.data.ranking && res.data.data.ranking.levelId
              ? res.data.data.ranking.levelId
              : null,
            res.data.data.ranking &&
              res.data.data.ranking.level &&
              res.data.data.ranking.level.name
              ? res.data.data.ranking.level.name
              : null,
            res.data.data.ranking && res.data.data.ranking.points
              ? res.data.data.ranking.points
              : null,
            res.data.data.specialty,
            res.data.data.ranking && res.data.data.ranking.position
              ? res.data.data.ranking.position
              : null
          );

          let _userToken = tokenModel.createTokenModel(
            res.data.data.accessToken || "",
            moment().format()
          );
          store.dispatch(setToken(_userToken));
          _return.user = user;

          resolve(_return);
        } else {
          reject(false);
        }
      })
      .catch((err) => {
        console.log("Erro setForgotPasswordRoutine", err);
        reject(err);
      });
  });
}

export async function validateLoginByEventUserAndToken(
  eventId,
  userEmail,
  token,
  tokenRecaptcha
) {
  var method = `${apiService.getUrl().firebase}hallvalidateaccesstokenv3?`;
  let _configList = store.getState().config.filter((c) => {
    return c.screenId === 4 || c.screenId === 9;
  });

  return new Promise((resolve, reject) => {
    const options = {
      url: method,
      method: "POST",
      data: {
        eventId: eventId,
        value: userEmail,
        token: token,
        configList: _configList,
        tokenRecaptcha: tokenRecaptcha,
      },
    };
    axios(options)
      .then((res) => {
        if (res.data.success) {
          const user = userModel.createUserModel(
            res.data.data.id,
            parseInt(eventId),
            res.data.data.name,
            res.data.data.email ? res.data.data.email : "",
            res.data.data.institution ? res.data.data.institution : "",
            res.data.data.avatarProfileLink
              ? res.data.data.avatarProfileLink
              : "",
            res.data.data.hallFirstTerm,
            res.data.data.hallSecondTerm,
            null,
            null,
            null,
            res.data.data.groupId,
            res.data.data.registration,
            res.data.data.registration,
            null,
            res.data.data.company,
            res.data.data.token,
            res.data.data.visibleToChat,
            res.data.data.ranking && res.data.data.ranking.levelId
              ? res.data.data.ranking.levelId
              : null,
            res.data.data.ranking &&
              res.data.data.ranking.level &&
              res.data.data.ranking.level.name
              ? res.data.data.ranking.level.name
              : null,
            res.data.data.ranking && res.data.data.ranking.points
              ? res.data.data.ranking.points
              : null,
            res.data.data.specialty,
            res.data.data.ranking && res.data.data.ranking.position
              ? res.data.data.ranking.position
              : null
          );

          let _userToken = tokenModel.createTokenModel(
            res.data.data.accessToken || "",
            moment().format()
          );
          store.dispatch(setToken(_userToken));

          resolve(user);
        } else {
          reject(false);
        }
      })
      .catch((err) => {
        console.log("Erro validateLoginByEventUserAndToken", err);
        reject(err);
      });
  });
}
