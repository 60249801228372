import React, { useContext } from "react";
import RegistrationPageContext from './RegistratioPageContext';
import { store } from "../../store";
import { getDic } from "../../assets/i18n/dictionary";
import ShowZendesk from '../../services/zendeskService';

//Imports de components
import {
    Container,
    FormColumn,
    FormRow,
    TextBig,
    TextSmall,
    Card,
    InputFieldOutlined,
    Button,
    ActivityIndicator,
    SelectList,
    Alert,
    Loading,
    Recaptcha,
    IframePlayer,
    DefaultTerm,
    Modal,
    Link,
    TextMedium,
    Checkbox
} from "../../components";

const RegistrationPageVisual = (props) => {
    // Busca o state e os métodos e instancia em uma variável context
    const { state, functions } = useContext(RegistrationPageContext);

    const renderTerms = () => {
        if (state.terms) {
            if (state.isIframeTerms) {
                return <IframePlayer height="80vh" src={state.terms} />;
            } else {
                return (
                    <div style={{ maxHeight: '500px' }} dangerouslySetInnerHTML={{ __html: state.terms }} />
                );
            }
        } else {
            return (
                <div style={{ maxHeight: '500px' }}>
                    <DefaultTerm />
                </div>
            );
        }
    }

    const renderCardContent = () => {
        return (
            <FormColumn align="center">
                <FormRow align="center">
                    <div style={{ width: "100%", maxWidth: "600px" }}>
                        <img alt="" src={state.image001} style={{ width: "100%" }} />
                    </div>
                </FormRow>
                <TextBig
                    fontSize={state.primaryTitle.size}
                    color={state.primaryTitle.color}
                >
                    {state.primaryTitle.text}
                </TextBig>
                <TextSmall
                    fontSize={state.secondaryTitle.size}
                    color={state.secondaryTitle.color}
                >
                    {state.secondaryTitle.text}
                </TextSmall>
                <br />
                <FormRow align="left" margin="10px">
                    <TextSmall
                        color={state.tittle005.color}
                        fontSize={state.tittle005.size}
                    >
                        {state.tittle005.text}
                    </TextSmall>
                </FormRow>
                {state.attributesValueList.map((item) => {
                    let _attribute = state.attributesList.find(a => { return a.id === item.attributeId });
                    if (item.type === "select" && item.values) {
                        return (
                            <FormRow key={item.id} align="center" paddingTop="5px">
                                <SelectList
                                    required={item.required}
                                    error={item.error}
                                    errorMessage={item.errorMessage}
                                    title={item.name}
                                    width="100%"
                                    value={item.value}
                                    menuItens={item.values}
                                    onChange={async (event) =>
                                        functions.onChangeHandler(item.id, event.target.value)
                                    }
                                    disabled={item.disabled}
                                />
                            </FormRow>
                        );
                    }
                    if (item.type === "text") {
                        return (
                            <FormRow key={item.id} align="center" paddingTop="5px">
                                <InputFieldOutlined
                                    key={item.id}
                                    required={item.required}
                                    maxLength={item.maxLength}
                                    error={item.error}
                                    errorMessage={item.errorMessage}
                                    value={item.value}
                                    title={item.name}
                                    onChange={(event) =>
                                        functions.onChangeHandler(item.id, event.target.value)
                                    }
                                    onKeyPress={(event) => functions.onKeyPressHandler(event.key)}
                                    mask={item.mask}
                                    disabled={item.disabled}
                                />
                            </FormRow>
                        );
                    }
                })}
                {state.hasPassword && (
                    <>
                        <FormRow align="center" paddingTop="5px">
                            <InputFieldOutlined
                                type="password"
                                required={true}
                                error={state.passwordControl1.error}
                                errorMessage={state.passwordControl1.errorMessage}
                                value={state.passwordControl1.value}
                                title={`${getDic("senha")}`}
                                onChange={(e) =>
                                    state.setPasswordControl1({ ...state.passwordControl1, value: e.target.value })
                                }
                            />
                        </FormRow>
                        <FormRow align="center" paddingTop="5px">
                            <InputFieldOutlined
                                type="password"
                                required={true}
                                error={state.passwordControl2.error}
                                errorMessage={state.passwordControl2.errorMessage}
                                value={state.passwordControl2.value}
                                title={`${getDic("confirmar")} ${getDic("senha")}`}
                                onChange={(e) =>
                                    state.setPasswordControl2({ ...state.passwordControl2, value: e.target.value })
                                }
                            />
                        </FormRow>
                    </>
                )}
                {state.hasLang && (
                    <div
                        style={{
                            textAlign: "left",
                            paddingBottom: "10px",
                            paddingTop: "10px",
                        }}
                    >
                        <SelectList
                            title={`${getDic('selecione')} ${getDic('idioma')}`}
                            width="50%"
                            value={state.language}
                            menuItens={state.languages}
                            onChange={async (e) => functions.changeLanguage(e.target.value)}
                        />
                    </div>
                )}
                {state.useRecaptcha && (
                    <FormRow align="flex-end" margin="0px" paddingTop="10px">
                        <Recaptcha
                            onChange={(res) => state.setRecaptchaVerify(res)}
                        />
                    </FormRow>
                )}
                {state.terms && (
                    <>
                        <br />
                        <FormRow align="center">
                            <Link color={state.tittle003.color}>
                                <TextMedium
                                    color={state.tittle003.color}
                                    fontSize={state.tittle003.size}
                                    fontWeight="bold"
                                    onClick={() => {
                                        state.setOpenModalTerms(true);
                                        state.setTermsCheckboxDisabled(false);
                                    }}
                                >
                                    {state.tittle003.text}
                                </TextMedium>
                            </Link>
                        </FormRow>
                        <FormRow align="center" margin="0px">
                            {state.isComboboxTerms ? (
                                <FormRow align="center" margin="0px">
                                    <div style={{ paddingTop: 12, paddingRight: 5 }}>
                                        <TextMedium
                                            fontWeight="bold"
                                            textColor={state.tittle004.color}
                                            fontSize={state.tittle004.size}
                                        >
                                            {state.tittle004.text}
                                        </TextMedium>
                                    </div>
                                    <SelectList
                                        width="100px"
                                        value={state.checkAuthorization}
                                        menuItens={[
                                            { text: getDic("nao"), value: false },
                                            { text: getDic("sim"), value: true }
                                        ]}
                                        onChange={async (event) => {
                                            state.setCheckAuthorization(event.target.value)
                                        }}
                                    />
                                </FormRow>
                            ) : (
                                <Checkbox
                                    text={state.tittle004.text}
                                    textColor={state.tittle004.color}
                                    fontSize={state.tittle004.size}
                                    checked={state.checkAuthorization}
                                    disabled={state.termsCheckboxDisabled}
                                    fontWeight="bold"
                                    onChange={() => {
                                        if (state.autoOpenTerms) {
                                            if (!state.termsCheckboxDisabled && !state.checkAuthorization) {
                                                state.setOpenModalTerms(true);
                                                state.setTermsCheckboxDisabled(false);
                                            }
                                            state.setCheckAuthorization(state.checkAuthorization === true ? false : true);
                                        } else {
                                            if (!state.termsCheckboxDisabled) {
                                                state.setCheckAuthorization(state.checkAuthorization === true ? false : true);
                                            }
                                            else {
                                                state.setAlertTitle(getDic('atencao'));
                                                state.setAlertMessage(`${getDic("necessario")} ${getDic("ler")} ${getDic("termos")}`);
                                                state.setAlertOpen(true);
                                            }
                                        }
                                    }}
                                />
                            )}
                        </FormRow>
                    </>
                )}
                <br />
                <Button
                    text={state.buttonText}
                    color={state.buttonColor}
                    onClick={(event) => functions.tryRegister()}
                />
                <br />

                <Button
                    color='lightBlue'
                    textColor='black'
                    text='Acesse / Login / Acceso'
                    onClick={() => functions.openSsoMicrosoftLoginPopup()}
                />

                <FormRow align="center" margin="0px">
                    <div style={{ width: "100%" }}>
                        <img alt="" src={state.image003} style={{ width: "100%" }} />
                    </div>
                </FormRow>
            </FormColumn>
        );
    }

    if (state.isLoading) {
        return (
            <Container background="#e8eced" align="center">
                <FormRow align="center">
                    <ActivityIndicator />
                </FormRow>
            </Container>
        );
    }
    return (
        <Container background={state.colorBackGround} align="center" heigth="100%">
            <FormColumn
                margin="0px"
                backgroundImage={state.imageBackgroundUrl}
                backgroundSize="100% 100%"
            >
                <div style={{ position: "relative", width: "100%", height: "100vh", overflow: "auto" }}>
                    <div className="admin-dashboard">
                        <FormRow
                            align="center"
                            background={state.colorBackGround}
                            margin="0px"
                            paddingBottom="10px"
                        >
                            <div
                                style={{
                                    width: window.innerWidth < 600 ? `${window.innerWidth}px` : "600px",
                                    textAlign: "center",
                                    marginTop: "10px",
                                }}
                            >
                                {!state.cardConfig ? (
                                    <Card>{renderCardContent()}</Card>
                                ) : state.cardConfig === "CARD-SIMP" ? (
                                    <Card cardType="simple">{renderCardContent()}</Card>
                                ) : (
                                    <Card cardType="transparent">{renderCardContent()}</Card>
                                )}
                            </div>
                        </FormRow>

                        <Alert
                            open={state.alertOpen}
                            onClose={() => state.setAlertOpen(false)}
                            onClick={() => state.setAlertOpen(false)}
                            title={state.alertTitle}
                            message={state.alertMessage}
                        />

                        <Modal
                            isVideo
                            open={state.openModalTerms}
                            closeButtonClick={(e) => {
                                state.setOpenModalTerms(false);
                            }}
                            onClose={(ret) => {
                                state.setOpenModalTerms(false);
                            }}
                        >
                            <Container background="#e8eced" align="left" height="100%">
                                {renderTerms()}
                            </Container>
                        </Modal>

                        <Loading open={state.openLoading} message={getDic("carregando")} />

                        <ShowZendesk />
                    </div>
                </div>
            </FormColumn>
        </Container>
    );
}

export default RegistrationPageVisual;
