import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { store } from "./store";
import {
  clearDate,
  setPrivateChatControl,
  resetChosenConfig,
} from "./store/actions";
import { setNewTheme } from "./services/themeService";
import { createThemeModel } from "./models/themeModel";
import { isMobile } from "react-device-detect";
import { initGoogleAnalytics } from "./services/eventService";
import blipChatService from "./services/blipChatService";

//Imports de páginas
// import LoginPage from "./pages/LoginPage/LoginPageOld";
import LoginPage from "./pages/LoginPage";
// import RegistrationPage from "./pages/RegistratioPage/RegistrationPageOld";
import RegistrationPage from "./pages/RegistratioPage";
import BadgePage from "./pages/BadgePage/BadgePageOld";
// import BadgePage from "./pages/BadgePage";
import EventHallPage from "./pages/EventHallPage";
import CreateAvatarPage from "./pages/CreateAvatarPage";
import Statistics from "./pages/Statistics";
import Transmission from "./pages/TransmissionPages/Transmission";
import Transmission2 from "./pages/TransmissionPages/Transmission2";
import Transmission3 from "./pages/TransmissionPages/Transmission3";
import Transmission4 from "./pages/TransmissionPages/Transmission4";
import Transmission5 from "./pages/TransmissionPages/Transmission5";
import Transmission6 from "./pages/TransmissionPages/Transmission6";
import Transmission7 from "./pages/TransmissionPages/Transmission7";
import Transmission8 from "./pages/TransmissionPages/Transmission8";
import Transmission9 from "./pages/TransmissionPages/Transmission9";
import Transmission10 from "./pages/TransmissionPages/Transmission10";
import Transmission11 from "./pages/TransmissionPages/Transmission11";
import Transmission12 from "./pages/TransmissionPages/Transmission12";
import Transmission13 from "./pages/TransmissionPages/Transmission13";
import Transmission14 from "./pages/TransmissionPages/Transmission14";
import Transmission15 from "./pages/TransmissionPages/Transmission15";
import Transmission16 from "./pages/TransmissionPages/Transmission16";
import Transmission17 from "./pages/TransmissionPages/Transmission17";
import Transmission18 from "./pages/TransmissionPages/Transmission18";
import Transmission19 from "./pages/TransmissionPages/Transmission19";
import Transmission20 from "./pages/TransmissionPages/Transmission20";
import Transmission21 from "./pages/TransmissionPages/Transmission21";
import Transmission22 from "./pages/TransmissionPages/Transmission22";
import Transmission23 from "./pages/TransmissionPages/Transmission23";
import Transmission24 from "./pages/TransmissionPages/Transmission24";
import Transmission25 from "./pages/TransmissionPages/Transmission25";
import Transmission26 from "./pages/TransmissionPages/Transmission26";
import Transmission27 from "./pages/TransmissionPages/Transmission27";
import Transmission28 from "./pages/TransmissionPages/Transmission28";
import Transmission29 from "./pages/TransmissionPages/Transmission29";
import Transmission30 from "./pages/TransmissionPages/Transmission30";
import Transmission31 from "./pages/TransmissionPages/Transmission31";
import Transmission32 from "./pages/TransmissionPages/Transmission32";
import Transmission33 from "./pages/TransmissionPages/Transmission33";
import Transmission34 from "./pages/TransmissionPages/Transmission34";
import Transmission35 from "./pages/TransmissionPages/Transmission35";
import Transmission36 from "./pages/TransmissionPages/Transmission36";
import Transmission37 from "./pages/TransmissionPages/Transmission37";
import Transmission38 from "./pages/TransmissionPages/Transmission38";
import Transmission39 from "./pages/TransmissionPages/Transmission39";
import Transmission40 from "./pages/TransmissionPages/Transmission40";
import Transmission41 from "./pages/TransmissionPages/Transmission41";
import Transmission42 from "./pages/TransmissionPages/Transmission42";
import Transmission43 from "./pages/TransmissionPages/Transmission43";
import Transmission44 from "./pages/TransmissionPages/Transmission44";
import Transmission45 from "./pages/TransmissionPages/Transmission45";
import Transmission46 from "./pages/TransmissionPages/Transmission46";
import Transmission47 from "./pages/TransmissionPages/Transmission47";
import Transmission48 from "./pages/TransmissionPages/Transmission48";
import Transmission49 from "./pages/TransmissionPages/Transmission49";
import Transmission50 from "./pages/TransmissionPages/Transmission50";
import Transmission51 from "./pages/TransmissionPages/Transmission51";
import Transmission52 from "./pages/TransmissionPages/Transmission52";
import Transmission53 from "./pages/TransmissionPages/Transmission53";
import Transmission54 from "./pages/TransmissionPages/Transmission54";
import Transmission55 from "./pages/TransmissionPages/Transmission55";
import Transmission56 from "./pages/TransmissionPages/Transmission56";
import Transmission57 from "./pages/TransmissionPages/Transmission57";
import Transmission58 from "./pages/TransmissionPages/Transmission58";
import Transmission59 from "./pages/TransmissionPages/Transmission59";
import Transmission60 from "./pages/TransmissionPages/Transmission60";
import Transmission61 from "./pages/TransmissionPages/Transmission61";
import Transmission62 from "./pages/TransmissionPages/Transmission62";
import Transmission63 from "./pages/TransmissionPages/Transmission63";
import Transmission64 from "./pages/TransmissionPages/Transmission64";
import Transmission65 from "./pages/TransmissionPages/Transmission65";
import Transmission66 from "./pages/TransmissionPages/Transmission66";
import Transmission67 from "./pages/TransmissionPages/Transmission67";
import Transmission68 from "./pages/TransmissionPages/Transmission68";
import Transmission69 from "./pages/TransmissionPages/Transmission69";
import Transmission70 from "./pages/TransmissionPages/Transmission70";
import Transmission71 from "./pages/TransmissionPages/Transmission71";
import Transmission72 from "./pages/TransmissionPages/Transmission72";
import Transmission73 from "./pages/TransmissionPages/Transmission73";
import Transmission74 from "./pages/TransmissionPages/Transmission74";
import Transmission75 from "./pages/TransmissionPages/Transmission75";
import Transmission76 from "./pages/TransmissionPages/Transmission76";
import Transmission77 from "./pages/TransmissionPages/Transmission77";
import Transmission78 from "./pages/TransmissionPages/Transmission78";
import Transmission79 from "./pages/TransmissionPages/Transmission79";
import Transmission80 from "./pages/TransmissionPages/Transmission80";
import Transmission81 from "./pages/TransmissionPages/Transmission81";
import Transmission82 from "./pages/TransmissionPages/Transmission82";
import Transmission83 from "./pages/TransmissionPages/Transmission83";
import Transmission84 from "./pages/TransmissionPages/Transmission84";
import Transmission85 from "./pages/TransmissionPages/Transmission85";
import Transmission86 from "./pages/TransmissionPages/Transmission86";
import Transmission87 from "./pages/TransmissionPages/Transmission87";
import Transmission88 from "./pages/TransmissionPages/Transmission88";
import Transmission89 from "./pages/TransmissionPages/Transmission89";
import Transmission90 from "./pages/TransmissionPages/Transmission90";
import Transmission91 from "./pages/TransmissionPages/Transmission91";
import Transmission92 from "./pages/TransmissionPages/Transmission92";
import Transmission93 from "./pages/TransmissionPages/Transmission93";
import Transmission94 from "./pages/TransmissionPages/Transmission94";
import Transmission95 from "./pages/TransmissionPages/Transmission95";
import Transmission96 from "./pages/TransmissionPages/Transmission96";
import Transmission97 from "./pages/TransmissionPages/Transmission97";
import Transmission98 from "./pages/TransmissionPages/Transmission98";
import Transmission99 from "./pages/TransmissionPages/Transmission99";
import Transmission100 from "./pages/TransmissionPages/Transmission100";
import RankingSurvey from "./pages/RankingSurvey";

import NotFoundPage from "./pages/NotFoundPage";
import SchedulesPage from "./pages/SchedulesPage";
import SpeakersPage from "./pages/SpeakersPage";
import StandsPage from "./pages/StandsPage";
import StandPage from "./pages/StandPage";
import DeskHallPage from "./pages/DeskHallPage";
import GeneralPage from "./pages/GeneralPage/GeneralPage";
import General2Page from "./pages/GeneralPage/General2Page";
import General3Page from "./pages/GeneralPage/General3Page";
import General4Page from "./pages/GeneralPage/General4Page";
import General5Page from "./pages/GeneralPage/General5Page";
import General6Page from "./pages/GeneralPage/General6Page";
import General7Page from "./pages/GeneralPage/General7Page";
import General8Page from "./pages/GeneralPage/General8Page";
import General9Page from "./pages/GeneralPage/General9Page";
import General10Page from "./pages/GeneralPage/General10Page";
import General11Page from "./pages/GeneralPage/General11Page";
import General12Page from "./pages/GeneralPage/General12Page";
import General13Page from "./pages/GeneralPage/General13Page";
import General14Page from "./pages/GeneralPage/General14Page";
import General15Page from "./pages/GeneralPage/General15Page";
import General16Page from "./pages/GeneralPage/General16Page";
import General17Page from "./pages/GeneralPage/General17Page";
import General18Page from "./pages/GeneralPage/General18Page";
import General19Page from "./pages/GeneralPage/General19Page";
import General20Page from "./pages/GeneralPage/General20Page";
import General21Page from "./pages/GeneralPage/General21Page";
import General22Page from "./pages/GeneralPage/General22Page";
import General23Page from "./pages/GeneralPage/General23Page";
import General24Page from "./pages/GeneralPage/General24Page";
import General25Page from "./pages/GeneralPage/General25Page";
import General26Page from "./pages/GeneralPage/General26Page";
import General27Page from "./pages/GeneralPage/General27Page";
import General28Page from "./pages/GeneralPage/General28Page";
import General29Page from "./pages/GeneralPage/General29Page";
import General30Page from "./pages/GeneralPage/General30Page";
import General31Page from "./pages/GeneralPage/General31Page";
import General32Page from "./pages/GeneralPage/General32Page";
import General33Page from "./pages/GeneralPage/General33Page";
import General34Page from "./pages/GeneralPage/General34Page";
import General35Page from "./pages/GeneralPage/General35Page";
import General36Page from "./pages/GeneralPage/General36Page";
import General37Page from "./pages/GeneralPage/General37Page";
import General38Page from "./pages/GeneralPage/General38Page";
import General39Page from "./pages/GeneralPage/General39Page";
import General40Page from "./pages/GeneralPage/General40Page";
import General41Page from "./pages/GeneralPage/General41Page";
import General42Page from "./pages/GeneralPage/General42Page";
import General43Page from "./pages/GeneralPage/General43Page";
import General44Page from "./pages/GeneralPage/General44Page";
import General45Page from "./pages/GeneralPage/General45Page";
import General46Page from "./pages/GeneralPage/General46Page";
import General47Page from "./pages/GeneralPage/General47Page";
import General48Page from "./pages/GeneralPage/General48Page";
import General49Page from "./pages/GeneralPage/General49Page";
import General50Page from "./pages/GeneralPage/General50Page";
import General51Page from "./pages/GeneralPage/General51Page";
import General52Page from "./pages/GeneralPage/General52Page";
import General53Page from "./pages/GeneralPage/General53Page";
import General54Page from "./pages/GeneralPage/General54Page";
import General55Page from "./pages/GeneralPage/General55Page";
import General56Page from "./pages/GeneralPage/General56Page";
import General57Page from "./pages/GeneralPage/General57Page";
import General58Page from "./pages/GeneralPage/General58Page";
import General59Page from "./pages/GeneralPage/General59Page";
import General60Page from "./pages/GeneralPage/General60Page";
import General61Page from "./pages/GeneralPage/General61Page";
import General62Page from "./pages/GeneralPage/General62Page";
import General63Page from "./pages/GeneralPage/General63Page";
import General64Page from "./pages/GeneralPage/General64Page";
import General65Page from "./pages/GeneralPage/General65Page";
import General66Page from "./pages/GeneralPage/General66Page";
import General67Page from "./pages/GeneralPage/General67Page";
import General68Page from "./pages/GeneralPage/General68Page";
import General69Page from "./pages/GeneralPage/General69Page";
import General70Page from "./pages/GeneralPage/General70Page";
import General71Page from "./pages/GeneralPage/General71Page";
import General72Page from "./pages/GeneralPage/General72Page";
import General73Page from "./pages/GeneralPage/General73Page";
import General74Page from "./pages/GeneralPage/General74Page";
import General75Page from "./pages/GeneralPage/General75Page";
import General76Page from "./pages/GeneralPage/General76Page";
import General77Page from "./pages/GeneralPage/General77Page";
import General78Page from "./pages/GeneralPage/General78Page";
import General79Page from "./pages/GeneralPage/General79Page";
import General80Page from "./pages/GeneralPage/General80Page";
import General81Page from "./pages/GeneralPage/General81Page";
import General82Page from "./pages/GeneralPage/General82Page";
import General83Page from "./pages/GeneralPage/General83Page";
import General84Page from "./pages/GeneralPage/General84Page";
import General85Page from "./pages/GeneralPage/General85Page";
import General86Page from "./pages/GeneralPage/General86Page";
import General87Page from "./pages/GeneralPage/General87Page";
import General88Page from "./pages/GeneralPage/General88Page";
import General89Page from "./pages/GeneralPage/General89Page";
import General90Page from "./pages/GeneralPage/General90Page";
import General91Page from "./pages/GeneralPage/General91Page";
import General92Page from "./pages/GeneralPage/General92Page";
import General93Page from "./pages/GeneralPage/General93Page";
import General94Page from "./pages/GeneralPage/General94Page";
import General95Page from "./pages/GeneralPage/General95Page";
import General96Page from "./pages/GeneralPage/General96Page";
import General97Page from "./pages/GeneralPage/General97Page";
import General98Page from "./pages/GeneralPage/General98Page";
import General99Page from "./pages/GeneralPage/General99Page";
import General100Page from "./pages/GeneralPage/General100Page";

import GuestsPage from "./pages/GuestsPage";
import ModeratorPage from "./pages/ModeratorPage";
import ModeratorV2 from "./pages/ModeratorV2";
import ReportsPage from "./pages/ReportsPage";
import UploadPage from "./pages/UploadPage";
import FilesPage from "./pages/FilesPage";
import QuestionsPage from "./pages/QuestionsPage";
import PushNotificationPage from "./pages/PushNotificationPage";
import ProfilePage from "./pages/ProfilePage";
import ReportsSurveyPage from "./pages/ReportsSurveyPage";
import ChatHistoryPage from "./pages/ChatHistoryPage";
import AdminReportsPage from "./pages/AdminReportsPage";
import ConfigPage from "./pages/ConfigPage";
import StandsConfigPage from "./pages/StandsConfigPage";
import PageCustom from "./pages/PageCustom/PageCustom";
import PageCustom2 from "./pages/PageCustom/PageCustom2";
import ChatPublicPage from "./pages/ChatPublicPage";
import QuestionsRegisterPage from "./pages/QuestionsRegisterPage";
import LevelRegisterPage from "./pages/LevelRegisterPage";
import RankingPage from "./pages/RankingPage";
import SchedulesRegisterPage from "./pages/SchedulesRegisterPage";
import SpeakersRegisterPage from "./pages/SpeakersRegisterPage";
import InteegraflixPage from "./pages/InteegraflixPage";
import GroupRegisterPage from "./pages/GroupRegisterPage";
import FeedPostPage from "./pages/FeedPostPage";
import CalendarPage from "./pages/CalendarPage";
import CalendarRegisterPage from "./pages/CalendarRegisterPage";
import VideoMp4 from "./components/VideoMp4";
import VideoM3U8 from "./components/VideoM3U8";
import CampaignRegisterPage from "./pages/CampaignRegisterPage";
import CampaignAdminPage from "./pages/CampaignAdminPage";
import WhatsappImporterPage from "./pages/WhatsappImporterPage";
import MobileOrientationInstructionsPage from "./pages/MobileOrientationInstructionsPage";
import SponsorsPage from "./pages/SponsorsPage";
import RafflePage from "./pages/RafflePage";
import RafflePresentationPage from "./pages/RafflePresentationPage";
import { NetworkChatV2 } from "./components";
// import TestPage from "./pages/LoginPageV2";
import TestPage from "./pages/TestPage";

//Lógica para autenticação
import { authenticated } from "./auth";

const Routes = (props) => {
  //Método que receberá a rota solicitada e irá verificar se o usuário está autenticado
  //Variável rest recebe todos os parâmetros passados no route
  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <React.Fragment>
        <Route
          {...rest}
          render={(props) =>
            authenticated(props) ? (
              //Se o autenticado retornar true, chamar component passando as props
              <Component {...props} />
            ) : (
              //Caso retorne false, redirecionar para outra página
              //A propriedade (state: {from: props.location}) serve para o usuário não perder o histórico de navegação
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
        <div
          id="poweredBy"
          className={rest.path}
          style={{
            width: "95vw",
            textAlign: "center",
            bottom: "0vh",
            marginTop: -17,
          }}
        >
          <span>Powered By INTEEGRA</span>
        </div>
      </React.Fragment>
    );
  };

  const PublicRoute = ({ ...rest }) => {
    return (
      <React.Fragment>
        <Route {...rest} />
        <div
          id="poweredBy"
          className={rest.path}
          style={{
            width: "95vw",
            textAlign: "center",
            bottom: "0vh",
            marginTop: -17,
          }}
        >
          <span>Powered By INTEEGRA</span>
        </div>
      </React.Fragment>
    );
  };

  //Verifica se possui state
  if (store) {
    //Se sim, aplicar configurações de cor
    if (store.getState().config) {
      const backgroundColor = store.getState().config.find((item) => {
        return item.codeScreen === "BACKGROUND-COLOR";
      });
      const backgroundImage = store.getState().config.find((item) => {
        return item.codeScreen === "BACKGROUND-IMAGE";
      });

      if (backgroundColor || backgroundImage) {
        var color =
          backgroundColor && backgroundColor.url
            ? backgroundColor.url[0]
            : "#212121";
        var image =
          backgroundImage && backgroundImage.url ? backgroundImage.url[0] : "";

        var theme = createThemeModel(color, image);
        setNewTheme(theme);
      }

      //Ativar vlibras caso exista a config
      const vlibras = store.getState().config.find((item) => {
        return item.codeScreen === "USE-VLIBRAS";
      });
      if (vlibras && !isMobile) {
        let helpButton = document.getElementById("vlibras");
        if (helpButton) {
          helpButton.style.display = "block";
        }
      } else {
        let helpButton = document.getElementById("vlibras");
        if (helpButton) {
          helpButton.style.display = "none";
        }
      }

      //Limpar o chosenConfig para não impactar no firebase
      if (store.getState().chosenConfig) {
        store.dispatch(resetChosenConfig());
      }

      //Ativar Google Analytics
      if (
        store.getState().event.hasGoogleAnalytics &&
        store.getState().event &&
        store.getState().event.id > 0
      ) {
        initGoogleAnalytics(store.getState().event.googleAnalyticsId);
      }
    }

    //Resetar horário do sertvidor salvo
    store.dispatch(clearDate());
  }

  React.useEffect(() => {
    if (store.getState().config) {
      const enablePowered = store.getState().config.find((item) => {
        return item.codeScreen === "POWEREDBY-TEXT";
      });

      const poweredBy = document.getElementById("poweredBy");
      if (poweredBy) {
        poweredBy.style.display = enablePowered ? "block" : "none";
      }
      if (enablePowered) {
        const labelConfigs = enablePowered.url[0].split("style")[1];
        if (poweredBy) {
          if (labelConfigs.indexOf(",") > -1) {
            let configSet = labelConfigs
              .replace("{", "")
              .replace("}", "")
              .split(",");
            poweredBy.style.color = configSet[0].replace("fontColor:", "");
            poweredBy.style.fontSize =
              configSet[1].replace("fontSize:", "") + "px";
          } else {
            if (labelConfigs.indexOf("fontColor") > -1) {
              poweredBy.style.color = labelConfigs
                .replace("fontColor:", "")
                .replace("{", "")
                .replace("}", "");
            } else {
              poweredBy.style.fontSize =
                labelConfigs
                  .replace("fontSize:", "")
                  .replace("{", "")
                  .replace("}", "") + "px";
            }
          }
        }
      }

      //Ativar chat do blip
      let _blipChat = store.getState().config.find((item) => {
        return item.codeScreen === "USE-BLIP-CHAT";
      });
      if (_blipChat && window?.location?.href?.includes(_blipChat.eventId)) {
        blipChatService.init(_blipChat);
      }
    }
  });

  return (
    <Switch>
      <PublicRoute exact path="/:id" component={LoginPage} />
      <PublicRoute
        exact
        path="/:id/registration"
        component={RegistrationPage}
      />
      <PrivateRoute exact path="/:id/statistics" component={Statistics} />
      <PrivateRoute exact path="/:id/badge" component={BadgePage} />
      <PrivateRoute exact path="/:id/eventhall" component={EventHallPage} />
      <PublicRoute exact path="/:id/pagecustom" component={PageCustom} />
      <PrivateRoute
        exact
        path="/:id/createavatar"
        component={CreateAvatarPage}
      />
      <PrivateRoute exact path="/:id/transmission" component={Transmission} />
      <PrivateRoute exact path="/:id/transmission2" component={Transmission2} />
      <PrivateRoute exact path="/:id/transmission3" component={Transmission3} />
      <PrivateRoute exact path="/:id/transmission4" component={Transmission4} />
      <PrivateRoute exact path="/:id/transmission5" component={Transmission5} />
      <PrivateRoute exact path="/:id/transmission6" component={Transmission6} />
      <PrivateRoute exact path="/:id/transmission7" component={Transmission7} />
      <PrivateRoute exact path="/:id/transmission8" component={Transmission8} />
      <PrivateRoute exact path="/:id/transmission9" component={Transmission9} />
      <PrivateRoute
        exact
        path="/:id/transmission10"
        component={Transmission10}
      />
      <PrivateRoute
        exact
        path="/:id/transmission11"
        component={Transmission11}
      />
      <PrivateRoute
        exact
        path="/:id/transmission12"
        component={Transmission12}
      />
      <PrivateRoute
        exact
        path="/:id/transmission13"
        component={Transmission13}
      />
      <PrivateRoute
        exact
        path="/:id/transmission14"
        component={Transmission14}
      />
      <PrivateRoute
        exact
        path="/:id/transmission15"
        component={Transmission15}
      />
      <PrivateRoute
        exact
        path="/:id/transmission16"
        component={Transmission16}
      />
      <PrivateRoute
        exact
        path="/:id/transmission17"
        component={Transmission17}
      />
      <PrivateRoute
        exact
        path="/:id/transmission18"
        component={Transmission18}
      />
      <PrivateRoute
        exact
        path="/:id/transmission19"
        component={Transmission19}
      />
      <PrivateRoute
        exact
        path="/:id/transmission20"
        component={Transmission20}
      />
      <PrivateRoute
        exact
        path="/:id/transmission21"
        component={Transmission21}
      />
      <PrivateRoute
        exact
        path="/:id/transmission22"
        component={Transmission22}
      />
      <PrivateRoute
        exact
        path="/:id/transmission23"
        component={Transmission23}
      />
      <PrivateRoute
        exact
        path="/:id/transmission24"
        component={Transmission24}
      />
      <PrivateRoute
        exact
        path="/:id/transmission25"
        component={Transmission25}
      />
      <PrivateRoute
        exact
        path="/:id/transmission26"
        component={Transmission26}
      />
      <PrivateRoute
        exact
        path="/:id/transmission27"
        component={Transmission27}
      />
      <PrivateRoute
        exact
        path="/:id/transmission28"
        component={Transmission28}
      />
      <PrivateRoute
        exact
        path="/:id/transmission29"
        component={Transmission29}
      />
      <PrivateRoute
        exact
        path="/:id/transmission30"
        component={Transmission30}
      />
      <PrivateRoute
        exact
        path="/:id/transmission31"
        component={Transmission31}
      />
      <PrivateRoute
        exact
        path="/:id/transmission32"
        component={Transmission32}
      />
      <PrivateRoute
        exact
        path="/:id/transmission33"
        component={Transmission33}
      />
      <PrivateRoute
        exact
        path="/:id/transmission34"
        component={Transmission34}
      />
      <PrivateRoute
        exact
        path="/:id/transmission35"
        component={Transmission35}
      />
      <PrivateRoute
        exact
        path="/:id/transmission36"
        component={Transmission36}
      />
      <PrivateRoute
        exact
        path="/:id/transmission37"
        component={Transmission37}
      />
      <PrivateRoute
        exact
        path="/:id/transmission38"
        component={Transmission38}
      />
      <PrivateRoute
        exact
        path="/:id/transmission39"
        component={Transmission39}
      />
      <PrivateRoute
        exact
        path="/:id/transmission40"
        component={Transmission40}
      />
      <PrivateRoute
        exact
        path="/:id/transmission41"
        component={Transmission41}
      />
      <PrivateRoute
        exact
        path="/:id/transmission42"
        component={Transmission42}
      />
      <PrivateRoute
        exact
        path="/:id/transmission43"
        component={Transmission43}
      />
      <PrivateRoute
        exact
        path="/:id/transmission44"
        component={Transmission44}
      />
      <PrivateRoute
        exact
        path="/:id/transmission45"
        component={Transmission45}
      />
      <PrivateRoute
        exact
        path="/:id/transmission46"
        component={Transmission46}
      />
      <PrivateRoute
        exact
        path="/:id/transmission47"
        component={Transmission47}
      />
      <PrivateRoute
        exact
        path="/:id/transmission48"
        component={Transmission48}
      />
      <PrivateRoute
        exact
        path="/:id/transmission49"
        component={Transmission49}
      />
      <PrivateRoute
        exact
        path="/:id/transmission50"
        component={Transmission50}
      />
      <PrivateRoute
        exact
        path="/:id/transmission51"
        component={Transmission51}
      />
      <PrivateRoute
        exact
        path="/:id/transmission52"
        component={Transmission52}
      />
      <PrivateRoute
        exact
        path="/:id/transmission53"
        component={Transmission53}
      />
      <PrivateRoute
        exact
        path="/:id/transmission54"
        component={Transmission54}
      />
      <PrivateRoute
        exact
        path="/:id/transmission55"
        component={Transmission55}
      />
      <PrivateRoute
        exact
        path="/:id/transmission56"
        component={Transmission56}
      />
      <PrivateRoute
        exact
        path="/:id/transmission57"
        component={Transmission57}
      />
      <PrivateRoute
        exact
        path="/:id/transmission58"
        component={Transmission58}
      />
      <PrivateRoute
        exact
        path="/:id/transmission59"
        component={Transmission59}
      />
      <PrivateRoute
        exact
        path="/:id/transmission60"
        component={Transmission60}
      />
      <PrivateRoute
        exact
        path="/:id/transmission61"
        component={Transmission61}
      />
      <PrivateRoute
        exact
        path="/:id/transmission62"
        component={Transmission62}
      />
      <PrivateRoute
        exact
        path="/:id/transmission63"
        component={Transmission63}
      />
      <PrivateRoute
        exact
        path="/:id/transmission64"
        component={Transmission64}
      />
      <PrivateRoute
        exact
        path="/:id/transmission65"
        component={Transmission65}
      />
      <PrivateRoute
        exact
        path="/:id/transmission66"
        component={Transmission66}
      />
      <PrivateRoute
        exact
        path="/:id/transmission67"
        component={Transmission67}
      />
      <PrivateRoute
        exact
        path="/:id/transmission68"
        component={Transmission68}
      />
      <PrivateRoute
        exact
        path="/:id/transmission69"
        component={Transmission69}
      />
      <PrivateRoute
        exact
        path="/:id/transmission70"
        component={Transmission70}
      />
      <PrivateRoute
        exact
        path="/:id/transmission71"
        component={Transmission71}
      />
      <PrivateRoute
        exact
        path="/:id/transmission72"
        component={Transmission72}
      />
      <PrivateRoute
        exact
        path="/:id/transmission73"
        component={Transmission73}
      />
      <PrivateRoute
        exact
        path="/:id/transmission74"
        component={Transmission74}
      />
      <PrivateRoute
        exact
        path="/:id/transmission75"
        component={Transmission75}
      />
      <PrivateRoute
        exact
        path="/:id/transmission76"
        component={Transmission76}
      />
      <PrivateRoute
        exact
        path="/:id/transmission77"
        component={Transmission77}
      />
      <PrivateRoute
        exact
        path="/:id/transmission78"
        component={Transmission78}
      />
      <PrivateRoute
        exact
        path="/:id/transmission79"
        component={Transmission79}
      />
      <PrivateRoute
        exact
        path="/:id/transmission80"
        component={Transmission80}
      />
      <PrivateRoute
        exact
        path="/:id/transmission81"
        component={Transmission81}
      />
      <PrivateRoute
        exact
        path="/:id/transmission82"
        component={Transmission82}
      />
      <PrivateRoute
        exact
        path="/:id/transmission83"
        component={Transmission83}
      />
      <PrivateRoute
        exact
        path="/:id/transmission84"
        component={Transmission84}
      />
      <PrivateRoute
        exact
        path="/:id/transmission85"
        component={Transmission85}
      />
      <PrivateRoute
        exact
        path="/:id/transmission86"
        component={Transmission86}
      />
      <PrivateRoute
        exact
        path="/:id/transmission87"
        component={Transmission87}
      />
      <PrivateRoute
        exact
        path="/:id/transmission88"
        component={Transmission88}
      />
      <PrivateRoute
        exact
        path="/:id/transmission89"
        component={Transmission89}
      />
      <PrivateRoute
        exact
        path="/:id/transmission90"
        component={Transmission90}
      />
      <PrivateRoute
        exact
        path="/:id/transmission91"
        component={Transmission91}
      />
      <PrivateRoute
        exact
        path="/:id/transmission92"
        component={Transmission92}
      />
      <PrivateRoute
        exact
        path="/:id/transmission93"
        component={Transmission93}
      />
      <PrivateRoute
        exact
        path="/:id/transmission94"
        component={Transmission94}
      />
      <PrivateRoute
        exact
        path="/:id/transmission95"
        component={Transmission95}
      />
      <PrivateRoute
        exact
        path="/:id/transmission96"
        component={Transmission96}
      />
      <PrivateRoute
        exact
        path="/:id/transmission97"
        component={Transmission97}
      />
      <PrivateRoute
        exact
        path="/:id/transmission98"
        component={Transmission98}
      />
      <PrivateRoute
        exact
        path="/:id/transmission99"
        component={Transmission99}
      />
      <PrivateRoute
        exact
        path="/:id/transmission100"
        component={Transmission100}
      />

      <PrivateRoute exact path="/:id/feed" component={FeedPostPage} />
      <PrivateRoute
        exact
        path="/:id/schedules&:category"
        component={SchedulesPage}
      />
      <PrivateRoute exact path="/:id/survey" component={RankingSurvey} />
      <PrivateRoute exact path="/:id/speakers" component={SpeakersPage} />
      <PrivateRoute exact path="/:id/stands" component={StandsPage} />
      <PrivateRoute exact path="/:id/stand" component={StandPage} />
      <PrivateRoute exact path="/:id/deskhall" component={DeskHallPage} />
      <PrivateRoute exact path="/:id/general" component={GeneralPage} />
      <PrivateRoute exact path="/:id/general2" component={General2Page} />
      <PrivateRoute exact path="/:id/general3" component={General3Page} />
      <PrivateRoute exact path="/:id/general4" component={General4Page} />
      <PrivateRoute exact path="/:id/general5" component={General5Page} />
      <PrivateRoute exact path="/:id/general6" component={General6Page} />
      <PrivateRoute exact path="/:id/general7" component={General7Page} />
      <PrivateRoute exact path="/:id/general8" component={General8Page} />
      <PrivateRoute exact path="/:id/general9" component={General9Page} />
      <PrivateRoute exact path="/:id/general10" component={General10Page} />
      <PrivateRoute exact path="/:id/general11" component={General11Page} />
      <PrivateRoute exact path="/:id/general12" component={General12Page} />
      <PrivateRoute exact path="/:id/general13" component={General13Page} />
      <PrivateRoute exact path="/:id/general14" component={General14Page} />
      <PrivateRoute exact path="/:id/general15" component={General15Page} />
      <PrivateRoute exact path="/:id/general16" component={General16Page} />
      <PrivateRoute exact path="/:id/general17" component={General17Page} />
      <PrivateRoute exact path="/:id/general18" component={General18Page} />
      <PrivateRoute exact path="/:id/general19" component={General19Page} />
      <PrivateRoute exact path="/:id/general20" component={General20Page} />
      <PrivateRoute exact path="/:id/general21" component={General21Page} />
      <PrivateRoute exact path="/:id/general22" component={General22Page} />
      <PrivateRoute exact path="/:id/general23" component={General23Page} />
      <PrivateRoute exact path="/:id/general24" component={General24Page} />
      <PrivateRoute exact path="/:id/general25" component={General25Page} />
      <PrivateRoute exact path="/:id/general26" component={General26Page} />
      <PrivateRoute exact path="/:id/general27" component={General27Page} />
      <PrivateRoute exact path="/:id/general28" component={General28Page} />
      <PrivateRoute exact path="/:id/general29" component={General29Page} />
      <PrivateRoute exact path="/:id/general30" component={General30Page} />
      <PrivateRoute exact path="/:id/general31" component={General31Page} />
      <PrivateRoute exact path="/:id/general32" component={General32Page} />
      <PrivateRoute exact path="/:id/general33" component={General33Page} />
      <PrivateRoute exact path="/:id/general34" component={General34Page} />
      <PrivateRoute exact path="/:id/general35" component={General35Page} />
      <PrivateRoute exact path="/:id/general36" component={General36Page} />
      <PrivateRoute exact path="/:id/general37" component={General37Page} />
      <PrivateRoute exact path="/:id/general38" component={General38Page} />
      <PrivateRoute exact path="/:id/general39" component={General39Page} />
      <PrivateRoute exact path="/:id/general40" component={General40Page} />
      <PrivateRoute exact path="/:id/general41" component={General41Page} />
      <PrivateRoute exact path="/:id/general42" component={General42Page} />
      <PrivateRoute exact path="/:id/general43" component={General43Page} />
      <PrivateRoute exact path="/:id/general44" component={General44Page} />
      <PrivateRoute exact path="/:id/general45" component={General45Page} />
      <PrivateRoute exact path="/:id/general46" component={General46Page} />
      <PrivateRoute exact path="/:id/general47" component={General47Page} />
      <PrivateRoute exact path="/:id/general48" component={General48Page} />
      <PrivateRoute exact path="/:id/general49" component={General49Page} />
      <PrivateRoute exact path="/:id/general50" component={General50Page} />
      <PrivateRoute exact path="/:id/general51" component={General51Page} />
      <PrivateRoute exact path="/:id/general52" component={General52Page} />
      <PrivateRoute exact path="/:id/general53" component={General53Page} />
      <PrivateRoute exact path="/:id/general54" component={General54Page} />
      <PrivateRoute exact path="/:id/general55" component={General55Page} />
      <PrivateRoute exact path="/:id/general56" component={General56Page} />
      <PrivateRoute exact path="/:id/general57" component={General57Page} />
      <PrivateRoute exact path="/:id/general58" component={General58Page} />
      <PrivateRoute exact path="/:id/general59" component={General59Page} />
      <PrivateRoute exact path="/:id/general60" component={General60Page} />
      <PrivateRoute exact path="/:id/general61" component={General61Page} />
      <PrivateRoute exact path="/:id/general62" component={General62Page} />
      <PrivateRoute exact path="/:id/general63" component={General63Page} />
      <PrivateRoute exact path="/:id/general64" component={General64Page} />
      <PrivateRoute exact path="/:id/general65" component={General65Page} />
      <PrivateRoute exact path="/:id/general66" component={General66Page} />
      <PrivateRoute exact path="/:id/general67" component={General67Page} />
      <PrivateRoute exact path="/:id/general68" component={General68Page} />
      <PrivateRoute exact path="/:id/general69" component={General69Page} />
      <PrivateRoute exact path="/:id/general70" component={General70Page} />
      <PrivateRoute exact path="/:id/general71" component={General71Page} />
      <PrivateRoute exact path="/:id/general72" component={General72Page} />
      <PrivateRoute exact path="/:id/general73" component={General73Page} />
      <PrivateRoute exact path="/:id/general74" component={General74Page} />
      <PrivateRoute exact path="/:id/general75" component={General75Page} />
      <PrivateRoute exact path="/:id/general76" component={General76Page} />
      <PrivateRoute exact path="/:id/general77" component={General77Page} />
      <PrivateRoute exact path="/:id/general78" component={General78Page} />
      <PrivateRoute exact path="/:id/general79" component={General79Page} />
      <PrivateRoute exact path="/:id/general80" component={General80Page} />
      <PrivateRoute exact path="/:id/general81" component={General81Page} />
      <PrivateRoute exact path="/:id/general82" component={General82Page} />
      <PrivateRoute exact path="/:id/general83" component={General83Page} />
      <PrivateRoute exact path="/:id/general84" component={General84Page} />
      <PrivateRoute exact path="/:id/general85" component={General85Page} />
      <PrivateRoute exact path="/:id/general86" component={General86Page} />
      <PrivateRoute exact path="/:id/general87" component={General87Page} />
      <PrivateRoute exact path="/:id/general88" component={General88Page} />
      <PrivateRoute exact path="/:id/general89" component={General89Page} />
      <PrivateRoute exact path="/:id/general90" component={General90Page} />
      <PrivateRoute exact path="/:id/general91" component={General91Page} />
      <PrivateRoute exact path="/:id/general92" component={General92Page} />
      <PrivateRoute exact path="/:id/general93" component={General93Page} />
      <PrivateRoute exact path="/:id/general94" component={General94Page} />
      <PrivateRoute exact path="/:id/general95" component={General95Page} />
      <PrivateRoute exact path="/:id/general96" component={General96Page} />
      <PrivateRoute exact path="/:id/general97" component={General97Page} />
      <PrivateRoute exact path="/:id/general98" component={General98Page} />
      <PrivateRoute exact path="/:id/general99" component={General99Page} />
      <PrivateRoute exact path="/:id/general100" component={General100Page} />
      <PrivateRoute exact path="/:id/guests" component={GuestsPage} />
      <PrivateRoute exact path="/:id/moderator" component={ModeratorPage} />
      <PrivateRoute exact path="/:id/moderatorv2" component={ModeratorV2} />
      <PrivateRoute
        exact
        path="/:id/questionsregister"
        component={QuestionsRegisterPage}
      />
      <PrivateRoute
        exact
        path="/:id/schedulesregister"
        component={SchedulesRegisterPage}
      />
      <PrivateRoute
        exact
        path="/:id/speakersregister"
        component={SpeakersRegisterPage}
      />
      <PrivateRoute
        exact
        path="/:id/levelregister"
        component={LevelRegisterPage}
      />
      <PrivateRoute exact path="/:id/ranking" component={RankingPage} />
      <PublicRoute
        path="/:id/questions&:internal/:category?/:questionid?"
        component={QuestionsPage}
      />
      <PrivateRoute exact path="/:id/reports" component={ReportsPage} />
      <PrivateRoute exact path="/:id/files" component={FilesPage} />
      <PrivateRoute
        exact
        path="/:id/adminreports"
        component={AdminReportsPage}
      />
      <PrivateRoute
        exact
        path="/:id/notifications"
        component={PushNotificationPage}
      />
      <PrivateRoute exact path="/:id/profile" component={ProfilePage} />
      <PrivateRoute exact path="/:id/chat" component={ChatHistoryPage} />
      <PrivateRoute exact path="/:id/chatv2" component={NetworkChatV2} />
      <PublicRoute
        path="/:id/surveyreport&:internal/:wordCloudColor?"
        component={ReportsSurveyPage}
      />
      {/* <PrivateRoute exact path="/:id/upload/:category" component={UploadPage} /> */}
      <PublicRoute
        path="/:id/upload/:user&:category&:contract&:internal"
        component={UploadPage}
      />
      <PrivateRoute exact path="/:id/config" component={ConfigPage} />

      <PrivateRoute
        exact
        path="/:id/standsconfig"
        component={StandsConfigPage}
      />
      <PrivateRoute
        exact
        path="/:id/inteegraflix"
        component={InteegraflixPage}
      />
      <PrivateRoute
        exact
        path="/:id/groupregister"
        component={GroupRegisterPage}
      />
      <PrivateRoute
        exact
        path="/:id/campaignregister"
        component={CampaignRegisterPage}
      />
      <PrivateRoute
        exact
        path="/:id/campaignadmin"
        component={CampaignAdminPage}
      />
      <PrivateRoute
        exact
        path="/:id/whatsappimporter"
        component={WhatsappImporterPage}
      />
      <PrivateRoute exact path="/:id/sponsors" component={SponsorsPage} />
      <PublicRoute
        path="/:id/chatpublic&:userid&:configtype&:configid&:internal"
        component={ChatPublicPage}
      />
      {/*<PublicRoute path="*" component={NotFoundPage} />*/}
      <PublicRoute path="/:id/calendar/:category" component={CalendarPage} />
      <PublicRoute
        path="/:id/calendarregister"
        component={CalendarRegisterPage}
      />
      <PublicRoute path="/:id/pagecustom2" component={PageCustom2} />
      <PublicRoute path="/:id/videomp4&:url" component={VideoMp4} />
      <PublicRoute path="/:id/videom3u8&:url" component={VideoM3U8} />
      <PublicRoute
        path="/:id/orientationinstructions"
        component={MobileOrientationInstructionsPage}
      />
      <PrivateRoute path="/:id/raffle" component={RafflePage} />
      <PrivateRoute path="/:id/rafflepresentation" component={RafflePresentationPage} />

      <Route path="/:id/test" component={TestPage} />
      <Route path="*" component={LoginPage} />
    </Switch>
  );
};

export default Routes;
