import * as apiService from './apiService';
import { store } from '../store';
import axios from 'axios';

export async function setAdminPlusRankingPoints(rankingCode = '', rankingCodeSubtract = false, guestId = '', eventId = '') {
  try {
    const _options = {
      url: `${apiService.getUrl().hallAuthenticationApi}guest/setAdminPlusRankingPoints`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        token: store.getState().user.securityToken,
        guestId: guestId,
        rankingCode: rankingCode,
        rankingCodeSubtract: rankingCodeSubtract ? false : true,
      }
    };

    await axios(_options);

  } catch (error) {
    console.log('Error setAdminPlusRankingPoints', error);
    throw error;
  }
}
