import React, { useState } from 'react';
import { getDic } from '../assets/i18n/dictionary';
import { getUrl } from '../services/apiService';
// import { Document, Page, pdfjs } from 'react-pdf';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

//Imports de components
import {
  FileIcons,
} from '../components';

export default function PdfThumbnail(props) {
  const {
    url,
    onClick,
    onLoadSuccess
  } = props;

  // const [loadError, setLoadError] = React.useState(false);

  // // const _url = `http://localhost:5001/inteegra-ping/us-central1/proxyWithCorsAnywhere/${url}`;
  // // const _url = `${getUrl().firebase}proxyWithCorsAnywhere/${url}`;
  // const _url = `${getUrl().corsAnywhere}${url}`;
  // // const _url = `https://gateway.inteegrav2.com.br/cors-anyware/${url}`;

  // pdfjs.GlobalWorkerOptions.workerSrc =
  //   `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  // /*To Prevent right click on screen*/
  // document.addEventListener("contextmenu", (event) => {
  //   event.preventDefault();
  // });

  // /*When document gets loaded successfully*/
  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   setPageNumber(1);
  //   onLoadSuccess();
  // }

  // function changePage(offset) {
  //   setPageNumber(prevPageNumber => prevPageNumber + offset);
  // }

  // function previousPage() {
  //   changePage(-1);
  // }

  // function nextPage() {
  //   changePage(1);
  // }

  // if (loadError) {
  //   // if (true) {
  //   return (
  //     <div style={{ paddingTop: 40, paddingBottom: 40, textAlign: "center", cursor: 'pointer' }}>
  //       <FileIcons
  //         extension="PDF"
  //         onClick={onClick}
  //       />
  //     </div>
  //   )
  // }

  return (
    <>
    </>
  )

  // return (
  //   <div style={{ width: "100%", height: "100%" }}>
  //     <div style={{ cursor: "pointer" }}>
  //       <Document
  //         file={_url}
  //         onLoadSuccess={onDocumentLoadSuccess}
  //         onLoadError={() => setLoadError(true)}
  //         onClick={onClick}
  //       >
  //         <Page width={390} pageNumber={pageNumber} />
  //       </Document>
  //     </div>
  //     <div>
  //       <div style={{ padding: 5 }}>
  //         {getDic("pagina")} {pageNumber || (numPages ? 1 : '--')} {getDic("de")} {numPages || '--'}
  //       </div>
  //       <div className="buttonc">
  //         <button
  //           type="button"
  //           disabled={pageNumber <= 1}
  //           onClick={previousPage}
  //           style={{ cursor: "pointer" }}
  //         >
  //           <ArrowBackIcon />
  //         </button>
  //         <button
  //           type="button"
  //           disabled={pageNumber >= numPages}
  //           onClick={nextPage}
  //           style={{ cursor: "pointer" }}
  //         >
  //           <ArrowForwardIcon />
  //         </button>
  //       </div>
  //     </div>
  //   </div>
  // );
}
