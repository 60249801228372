import axios from 'axios';
import { getUrl, getTokenByEventId, getDate } from './apiService';
import { store } from '../store';
import { getEventFromApiReturn } from './eventService';
import { getConfigListAndStandsAndPavilionsFromApiReturn } from './configService';
import { getSchedulesAndSpeakersAndConfigListFromApiReturn } from './scheduleService';
import { resetConfig } from '../store/actions';

export async function syncData(eventId) {

    // var method = getUrl().hall + 'event/' + eventId.toString();
    var method = getUrl().hallAuthenticationApi + 'user/getConfiguration';

    return new Promise((resolve, reject) => {

        const options = {
            url: method,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        axios(options)
            .then(async res => {
                if (res.data.success) {
                    //Reseta as configurações
                    await store.dispatch(resetConfig());

                    //Verifica se o evento está ok e salva no redux
                    const eventRet = await getEventFromApiReturn(res.data.data);
                    if (!eventRet.success)
                        reject(eventRet.message);

                    const schedRet = await getSchedulesAndSpeakersAndConfigListFromApiReturn(res.data.data);
                    if (!schedRet.success)
                        reject(schedRet.message);

                    const configRet = await getConfigListAndStandsAndPavilionsFromApiReturn(res.data.data);
                    if (!configRet.success)
                        reject(configRet.message);

                    resolve(true);
                }
                else {
                    reject(res.data);
                }
            })
            .catch(err => {
                console.log("Erro syncData", err);
                reject(err);
            })
    });
}
