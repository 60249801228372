import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import { setUserChatVisibility, updateUserApi } from '../services/userService';
import { createFormControlModel } from '../models/formControlModel';
import { getFileExtension } from '../utils';
import { addNewUserImageApi } from '../services/fileService';
import { userLoginSuccess } from '../store/actions';
import { validateForm } from '../utils';
import { getConfigListByPage } from '../services/configService';
import { getAttributes } from "../services/attributesService";
import { getPointComposition, getRankingApi } from '../services/gameficationService';
import { createRankingModel } from "../models/gameficationModel";
import CameraIcon from '@material-ui/icons/AddAPhoto';
import FaceIcon from '@material-ui/icons/Face';
import CreateAvatarPage from '../pages/CreateAvatarPage';
import moment from "moment";
import * as rankingService from '../services/rankingService';

//Imports de components
import {
	MenuButton,
	BackButton,

	Card,
	Alert,
	Loading,
	Container,
	FormRow,
	FormColumn,
	Avatar,
	FabButton,
	TextMedium,
	TextBig,
	Button,
	Modal,
	InputFieldOutlined,
	TextSmall,
	SelectList,
	GridCell,
	GridRow,
	DialogDraggable
} from '../components';

const ProfilePage = (props) => {
	const [state, setState] = React.useState(true);
	//Alert control
	const [alertOpen, setAlertOpen] = React.useState(false);
	const [alertTitle, setAlertTitle] = React.useState('');
	const [alertMessage, setAlertMessage] = React.useState('');

	//Loading control
	const [loadingMessage, setLoadingMessage] = React.useState(getDic('carregando'));
	const [openLoading, setOpenLoading] = React.useState(false);

	//Modal control
	const [openModalNewPhoto, setOpenModalNewPhoto] = React.useState(false);
	const [openModalPointComposition, setOpenModalPointComposition] = React.useState(false);
	const [openModalCreateAvatar, setOpenModalCreateAvatar] = React.useState(false);
	const [fileNewPhoto, setFileNewPhoto] = React.useState(null);

	//Form Control
	const [formList, setFormList] = React.useState([]);
	const [isEditing, setIsEditing] = React.useState(false);
	const [hasAvatarCreator, setHasAvatarCreator] = React.useState(true);
	const [hasChatVisibility, setHasChatVisibility] = React.useState(false);
	const [imageBackgroundUrl, setImageBackgroundUrl] = React.useState(null);
	const [colorBackGround, setColorBackGround] = React.useState('#e8eced');
	const [cardConfig, setCardConfig] = React.useState(null);
	const [button001Color, setCutton001Color] = React.useState("#3f51b5");
	const [rankingList, setRankingList] = React.useState([]);
	const [textPoints, setTextPoints] = React.useState(getDic("pontos"));
	const [userPoints, setUserPoints] = React.useState(props.user.points);
	const [pointComposition, setPointComposition] = React.useState([]);
	const [maxPoints, setMaxPoints] = React.useState(null);
	const [hasPoints, setHasPoints] = React.useState(true);
	const [hasPosition, setHasPosition] = React.useState(true);

	React.useEffect(() => {
		const _url = !props.isModal ? props.match.url : `/${props.event.id}/profile`;
		getConfigListByPage(_url).then((configList) => {
			configList.find((item) => {
				if (item.codeScreen === 'BACKGROUND-COLOR') {
					setColorBackGround(item.url[0]);
				}
				if (item.codeScreen === 'IMAGE-001') {
					setImageBackgroundUrl(item.url[0]);
					setColorBackGround('transparent');
				}
				if (item.codeScreen === 'CARD-SIMP') {
					setCardConfig('simple');
				}
				if (item.codeScreen === 'CARD-TRAN') {
					setCardConfig('transparent');
				}
				if (item.codeScreen === 'BUTTON-001') {
					setCutton001Color(item.url[0]);
				}
				if (item.codeScreen === 'NO-AVATAR-CREATE') {
					setHasAvatarCreator(false);
				}
				if (item.codeScreen === 'ELEMENT-001') {
					setMaxPoints(item.tooltip);
				}
				if (item.codeScreen === 'USE-CHAT-VISIBILITY') {
					setHasChatVisibility(true);
				}
				if (item.codeScreen === 'DISABLE-POINTS') {
					setHasPoints(false);
				}
				if (item.codeScreen === 'DISABLE-POINTS-POSITION') {
					setHasPosition(false);
				}
				return null;
			});

			//Cria o form control
			const _formControl = [];

			const input1 = createFormControlModel(
				1,
				getDic('nome'),
				props.user.name,
				'text',
				null,
				null,
				null,
				true,
				null,
				null,
				true
			);
			_formControl.push(input1);

			//Lógica invertida
			//Ao ativar modo anonimo o usuário seta visibleChat como false
			const _anonimousValues = [];
			const _val1 = {
				text: getDic('nao'),
				value: '1',
				realValue: true
			};
			_anonimousValues.push(_val1);
			const _val2 = {
				text: getDic('sim'),
				value: '0',
				realValue: false
			};
			_anonimousValues.push(_val2);

			const input2 = createFormControlModel(
				2,
				`${getDic("visivel")} ${getDic("ao")} Chat`,
				props.user.visibleToChat ? '0' : '1',
				'select',
				null,
				null,
				_anonimousValues,
				true,
				null,
				null,
				true
			);
			_formControl.push(input2);
			setFormList(_formControl);

			if (!props.isModal) {
				insertNewAccess(props.match.url, "", getSystemActions().accessPage);
			}
		})

		//Verifica se o usuário está em gamefication, se sim, mostrar posição e pontuação
		if (props.user.levelId && props.user.levelId > 0) {
			getRankingList();
		}

		return () => {
			if (!props.isModal) {
				insertNewAccess(props.match.url, "", getSystemActions().exitPage);
			}
		};
	}, []);

	const getRankingList = () => {
		setOpenLoading(true);

		getRankingApi()
			.then(res => {
				const _rank = insertMyPosition(res);
				setRankingList(_rank);

				_rank.find(rk => {
					if (rk.guestId === props.user.id) {
						setUserPoints(rk.points);
						return;
					}
				})
			})
			.catch(err => {
				var _hasRanking = true;
				if (err.toString().includes("status code 404")) {
					_hasRanking = false;
				}
				setAlertOpen(true);
				setAlertTitle(_hasRanking ? getDic("erro") : "");
				setAlertMessage(_hasRanking ? getDic("sem-conexao") : getDic("pesquisa-sem-resultado"));
			})
			.finally(() => {
				setOpenLoading(false);
			})

		var _pointsText = props.config.find(item => {
			return item.screenId === 20 && item.codeScreen === "ELEMENT-004";
		})
		if (_pointsText) {
			if (_pointsText.url.length > 0) {
				_pointsText.url.forEach(url => {
					var conf = url.split(":");
					if (conf[0] === "text") {
						setTextPoints(conf[1]);
					}
				});
			}
		}
	}

	const getPointCompositionLocal = () => {
		setOpenLoading(true);

		getPointComposition()
			.then(res => {
				setPointComposition(res);
				setOpenModalPointComposition(true);
			})
			.catch(err => {
				var _hasRanking = true;
				if (err.toString().includes("status code 404")) {
					_hasRanking = false;
				}
				setAlertOpen(true);
				setAlertTitle(_hasRanking ? getDic("erro") : "");
				setAlertMessage(_hasRanking ? getDic("sem-conexao") : getDic("pesquisa-sem-resultado"));
			})
			.finally(() => {
				setOpenLoading(false);
			})
	}

	const insertMyPosition = (ranking) => {
		var _userInRank = false;
		ranking.forEach(item => {
			if (item.guestId === props.user.id) {
				_userInRank = true;
			}
		});
		const _objReturn = ranking;
		if (!_userInRank) {
			const _myRecord = createRankingModel(
				11,
				props.user.id,
				props.user.name,
				props.user.points,
				props.user.levelId,
				props.user.levelName,
				props.user.image,
			)
			_objReturn.push(_myRecord);
		}
		return _objReturn;
	}

	const onChange = (e) => {
		setFileNewPhoto(e.target.files[0]);
	};

	const registerNewImage = () => {
		setLoadingMessage(getDic('enviando'));
		setOpenLoading(true);

		if (fileNewPhoto) {
			//Verifica se há restrições para envio de arquivo, se sim, checar so o arquivo está de acordo
			var fileType = getFileExtension(fileNewPhoto);
			if (fileType !== 'IMG' && fileType !== 'JPEG' && fileType !== 'JPG' && fileType !== 'PNG') {
				setAlertTitle(getDic('erro'));
				setAlertMessage(getDic('arquivo-erro-tipo'));
				setAlertOpen(true);
				setOpenLoading(false);
			} else {
				addNewUserImageApi(fileNewPhoto)
					.then(async (res) => {
						var newUser = props.user;
						newUser.image = res;
						await props.userLoginSuccess(newUser);

						try {
							await rankingService.setAdminPlusRankingPoints(
								'AVATAR2',
								false,
								newUser.internalCode,
								'50acc980-5b59-48b6-bca1-c33e713827cf'
							);
						} catch (error) {
							console.log('Error setAdminPlusRankingPoints', error);
						}

						setAlertTitle('');
						setAlertMessage(getDic('enviado-sucesso'));
						setAlertOpen(true);
						setFileNewPhoto(null);
						setOpenModalNewPhoto(false);
					})
					.catch((err) => {
						setAlertTitle(getDic('erro'));
						setAlertMessage(getDic('enviado-erro'));
						setAlertOpen(true);
					})
					.finally(() => {
						setOpenLoading(false);
					});
			}
		} else {
			setAlertTitle('');
			setAlertMessage(getDic('arquivo-nao-selecionado-erro-upload'));
			setAlertOpen(true);
			setOpenLoading(false);
		}
	};

	const enableDisableEdit = () => {
		//Método para ativar ou desativar os campos
		var _setDisabled;
		if (isEditing) {
			_setDisabled = true;
			setIsEditing(false);
		} else {
			_setDisabled = false;
			setIsEditing(true);
		}

		var _data = formList;
		formList.forEach((item, i) => {
			_data[i].disabled = _setDisabled;
		});
		setFormList(_data);
		setState(state === true ? false : true);
	};

	const registerUser = async () => {
		var validation = validateForm(formList);

		if (validation.isValid) {
			setOpenLoading(true);
			setLoadingMessage(getDic('salvando'));

			getAttributes(props.event.id)
				.then(async (res) => {
					var _user = props.user;

					_user.name = formList[0].value;

					var _realValue = formList[1].value === '0' ? true : false;

					if (_user.visibleToChat !== _realValue) {
						_user.visibleToChat = _realValue;

						await setUserChatVisibility(_realValue);
					}

					await props.userLoginSuccess(_user);

					enableDisableEdit();

					//Busca o atributo nome
					var _list = [];

					res.forEach((item) => {
						if (item.dbReference && item.dbReference.toUpperCase() === "NOME") {
							var _name = createFormControlModel(
								item.id,
								item.name,
								_user.name,
								item.type === "Selecionar" ? "select" : "text",
								item.maxLength,
								item.mask,
								item.values,
								item.required,
								null,
								null,
								null,
								item.dbReference
							);
							_list.push(_name);
						}
					});

					await updateUserApi(_user, _list, null)
						.then(async (userReturn) => {
							console.log("Participante atualizado");
						})
						.catch((err) => {
							console.log("Erro updateUserApi", err);
							setAlertTitle(getDic('erro'));
							setAlertMessage(getDic('enviado-erro'));
							setAlertOpen(true);
						});

				})
				.catch((err) => {
					setAlertTitle(getDic('erro'));
					setAlertMessage(getDic('enviado-erro'));
					setAlertOpen(true);
				})
				.finally(() => {
					setOpenLoading(false);
				})

		} else {
			setFormList(validation.form);
		}
	};

	return (
		<Container
			backgroundImage={imageBackgroundUrl}
			background={colorBackGround}
			align="center"
			height={props.isModal ? "100%" : "100vh"}
			backgroundSize="100% 100%"
		>
			<div style={{ position: "relative", width: "100%", height: "100%", overflow: "auto" }}>
				<FormRow align="center" background={colorBackGround} margin="0px">
					{!props.isModal && (
						<>
							<MenuButton history={props.history} />
							<BackButton history={props.history} color="#212121">
								{getDic("voltar").toUpperCase()}
							</BackButton>


							<FormRow minHeight="60px" height="5vh" align="center" background={colorBackGround} margin="0px" />
						</>
					)}

					<FormRow align="center" background={colorBackGround} margin="0px">
						<div
							style={{
								width: '100%',
								maxWidth: '420px',
								textAlign: 'center',
								marginTop: '10px',
								marginBottom: '10px'
							}}
						>
							<Card cardType={cardConfig}>
								<FormColumn align="center" overflow="hidden">
									<TextBig>{`${getDic('meu').toUpperCase()} ${getDic('perfil').toUpperCase()}`}</TextBig>
									<div>
										<FormRow align="center">
											<Avatar src={props.user.image} size="big" />
											{hasPosition && rankingList.length > 0 && props.user.levelPosition <= 3 && (
												<div style={{
													width: 30,
													height: 30,
													position: "absolute",
													zIndex: 12,
													borderRadius: 12,
													color: "white",
													cursor: "pointer",
													marginRight: 100
												}}>
													<img
														src={
															props.user.levelPosition === 1 ? "https://s3.amazonaws.com/bucket.aws.public/2020/197218.PNG" :
																props.user.levelPosition === 2 ? "https://s3.amazonaws.com/bucket.aws.public/2020/197219.PNG" :
																	props.user.levelPosition === 3 ? "https://s3.amazonaws.com/bucket.aws.public/2020/197220.PNG" : null
														}
														style={{
															height: 30,
															width: 30
														}}
													/>
												</div>
											)}
										</FormRow>
										{isEditing && (
											<div className="icons-profile" style={{ marginTop: '-45px' }}>
												{hasAvatarCreator && (
													<a
														className="link-create-avatar"
														color="#e0e0e0"
														textColor="black"
														onClick={() => setOpenModalCreateAvatar(true)}
													>
														<FaceIcon />
													</a>
												)}
												<FabButton
													color="#e0e0e0"
													textColor="black"
													onClick={() => setOpenModalNewPhoto(true)}
												>
													<CameraIcon />
												</FabButton>
											</div>
										)}
										{rankingList.length > 0 && hasPoints && (
											<>
												{hasPosition && props.user.levelPosition > 3 && (
													<FormRow align="center">
														<TextMedium>
															{getDic("posicao").toUpperCase()} {props.user.levelPosition}
														</TextMedium>
													</FormRow>
												)}
												<FormRow align="center">
													<TextBig>
														{textPoints} {userPoints}
													</TextBig>
												</FormRow>
												<FormRow align="center">
													<Button text={`${getDic('composicao')} ${textPoints}`} onClick={() => getPointCompositionLocal()} />
												</FormRow>
											</>
										)}
									</div>
									<br />
									{formList.length > 0 && (
										<div>
											<FormRow key={formList[0].id} margin="0px" align="center" paddingTop="10px">
												<InputFieldOutlined
													required={formList[0].required}
													maxLength={formList[0].maxLength}
													error={formList[0].error}
													errorMessage={formList[0].errorMessage}
													value={formList[0].value}
													title={formList[0].name}
													onChange={async (event) => {
														var data = formList;
														data[0].value = event.target.value;
														setFormList(data);
														setState(state === true ? false : true);
													}}
													mask={formList[0].mask}
													disabled={formList[0].disabled}
												/>
											</FormRow>
											{hasChatVisibility && (
												<FormRow
													key={formList[1].id}
													margin="0px"
													align="center"
													paddingTop="10px"
													paddingBottom="5px"
												>
													<SelectList
														required={formList[1].required}
														error={formList[1].error}
														errorMessage={formList[1].errorMessage}
														width="100%"
														title={formList[1].name}
														value={formList[1].value}
														menuItens={formList[1].values}
														onChange={async (event) => {
															var data = formList;
															data[1].value = event.target.value;
															setFormList(data);
															setState(state === true ? false : true);
														}}
														disabled={formList[1].disabled}
													/>
												</FormRow>
											)}
										</div>
									)}
									<FormRow margin="0px" align="center" paddingTop="10px">
										<InputFieldOutlined
											value={props.user.email}
											title={getDic('email')}
											disabled={true}
										/>
									</FormRow>
									{props.user.company && props.user.company !== '' ? (
										<FormRow margin="0px" align="center" paddingTop="10px">
											<InputFieldOutlined
												value={props.user.company}
												title={getDic('empresa')}
												disabled={true}
											/>
										</FormRow>
									) : null}
									{props.user.position && props.user.position !== '' ? (
										<FormRow margin="0px" align="center" paddingTop="10px">
											<InputFieldOutlined
												value={props.user.position}
												title={getDic('cargo')}
												disabled={true}
											/>
										</FormRow>
									) : null}
									<br />
									{isEditing ? (
										<Button color={button001Color} text={getDic('salvar')} onClick={() => registerUser()} />
									) : (
										<Button color={button001Color} text={getDic('editar')} onClick={() => enableDisableEdit()} />
									)}
								</FormColumn>
							</Card>
						</div>
					</FormRow>

					<Modal
						isVideo
						open={openModalNewPhoto}
						closeButtonClick={(e) => setOpenModalNewPhoto(false)}
						onClose={(e) => setOpenModalNewPhoto(false)}
					>
						<Container background="#e8eced" align="left" height="100%">
							<FormColumn>
								<FormRow align="center">
									<TextBig>Upload</TextBig>
								</FormRow>
								<FormRow align="center">
									<div style={{ maxWidth: '400px', marginRight: '10px' }}>
										<InputFieldOutlined
											key="file"
											type="file"
											title={getDic('arquivo')}
											placeholder={getDic('arquivo')}
											onChange={(e) => onChange(e)}
										/>
									</div>
									<Button text={getDic('enviar')} onClick={() => registerNewImage()} />
								</FormRow>
								<FormRow align="center">
									<TextSmall>{getDic('formatos-permitidos')}: img, jpeg, jpg, png</TextSmall>
								</FormRow>
							</FormColumn>
						</Container>
					</Modal>

					<Modal
						isVideo
						open={openModalPointComposition}
						closeButtonClick={(e) => setOpenModalPointComposition(false)}
						onClose={(e) => setOpenModalPointComposition(false)}
					>
						<Container background="#e8eced" align="left" height="100%">
							<FormColumn align="center" background="#e8eced" margin="0px" padding="5px">
								<GridRow backgroundColor="#d9d9d9">
									<GridCell width="20%" border top first>
										<TextMedium>{`${getDic("tipo").toUpperCase()} ${getDic("acao").toUpperCase()}`}</TextMedium>
									</GridCell>
									<GridCell width="25%" border top>
										<TextMedium>{`${getDic("pontos").toUpperCase()}`}</TextMedium>
									</GridCell>
									<GridCell width="35%" border top>
										<TextMedium>{`${getDic("descricao").toUpperCase()}`}</TextMedium>
									</GridCell>
									<GridCell width="20%" border top>
										<TextMedium>{`${getDic("data").toUpperCase()}`}</TextMedium>
									</GridCell>
								</GridRow>

								{pointComposition.map((item, i) => {
									return (
										<GridRow
											key={item.id}
											autoColor={i}
										>
											<GridCell width="20%" border first>
												<TextMedium>{item.actionType}</TextMedium>
											</GridCell>
											<GridCell width="25%" border>
												<TextMedium>{item.points}</TextMedium>
											</GridCell>
											<GridCell width="35%" border>
												<TextMedium>{item.description}</TextMedium>
											</GridCell>
											<GridCell width="20%" border>
												<TextMedium>{moment(item.dateCreated).format('D MMMM YYYY, hh:mm:ss')}</TextMedium>
											</GridCell>
										</GridRow>
									);
								})}

								<TextBig>
									Total {userPoints}
								</TextBig>
								{maxPoints && (
									<TextMedium>
										{maxPoints}
									</TextMedium>
								)}
							</FormColumn>
						</Container>
					</Modal>

					<Modal
						isVideo
						open={openModalCreateAvatar}
						closeButtonClick={(e) => setOpenModalCreateAvatar(false)}
						onClose={(e) => setOpenModalCreateAvatar(false)}
					>
						<CreateAvatarPage
							closeModal={() => setOpenModalCreateAvatar(false)}
							isModal
						/>
					</Modal>

					<Alert
						open={alertOpen}
						onClose={() => setAlertOpen(false)}
						onClick={() => setAlertOpen(false)}
						title={alertTitle}
						message={alertMessage}
					/>

					<Loading open={openLoading} message={loadingMessage} />
				</FormRow>
			</div>
		</Container>
	);
};

function mapStateToProps(state) {
	return {
		event: state.event,
		user: state.user,
		config: state.config
	};
}

const mapDispatchToProps = {
	userLoginSuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
