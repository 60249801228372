import * as msal from "@azure/msal-browser";
import { store } from "../store";
import * as actions from '../store/actions';
import * as apiService from './apiService';
import * as attributesService from "./attributesService";
import * as userModel from '../models/userModel';
import * as tokenModel from '../models/tokenModel';
import * as rankingService from './rankingService';
import axios from "axios";
import moment from 'moment';
import { createConfigModel } from "../models/configModel";

let msalConfig = {
  auth: {
    clientId: '',
    authority: '',
    // redirectUri: ''
  },
};
let msalInstance = new msal.PublicClientApplication(msalConfig);

export default {
  async initialize(config = createConfigModel()) {
    try {
      let _params = {
        clientId: '',
        authorityId: '',
      }

      for (let i = 0; i < config.url.length; i++) {
        const _item = config.url[i].split(':');
        _params[_item[0]] = _item[1];
      }

      if (_params.clientId && _params.authorityId) {
        msalConfig = {
          auth: {
            clientId: _params.clientId,
            authority: `https://login.microsoftonline.com/${_params.authorityId}`,
          },
        };
      } else {
        throw new Error('USE-SSO-MICROSOFT_INVALID_PARAMS');
      }

      msalInstance = new msal.PublicClientApplication(msalConfig);

      await msalInstance.initialize();
    } catch (error) {
      console.log('Error initialize', error);
      throw error;
    }
  },

  async openLoginPopup() {
    try {
      let _res = await msalInstance.loginPopup({
        scopes: ["openid", "profile", "email"],
      });

      let _user = await getGuest(_res.account.username, _res.account.name);

      let _return = userModel.createUserModel(
        _user.id,
        store.getState().event.id,
        _user.name,
        _user.email,
        _user.institution ? _user.institution : "",
        _user.avatarProfileLink ? _user.avatarProfileLink : "",
        _user.hallFirstTerm,
        _user.hallSecondTerm,
        null,
        null,
        null,
        _user.groupId,
        _user.codInternal,
        _user.internalCode,
        _user.password,
        _user.company,
        _user.visibleToChat,
        _user.ranking && _user.ranking.levelId
          ? _user.ranking.levelId
          : null,
        _user.ranking && _user.ranking.level && _user.ranking.level.name
          ? _user.ranking.level.name
          : null,
        _user.ranking && _user.ranking.points ? _user.ranking.points : null,
        _user.specialty,
        _user.ranking && _user.ranking.position
          ? _user.ranking.position
          : null,
        null,
        _user.securityToken,
      );

      let _userToken = tokenModel.createTokenModel(
        _user.accessToken || "",
        moment().format()
      );
      store.dispatch(actions.setToken(_userToken));

      await setPointsOnMicePlus(_return.internalCode);

      return _return;

    } catch (error) {
      console.log('Error openLoginPopup', error);
      throw error;
    }

    async function getGuest(email = '', name = '') {
      try {
        const options = {
          url: `${apiService.getUrl().hallAuthenticationApi}guest/loginGuest`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            email: email,
            name: name,
          },
        };

        let _res = await axios(options);

        let _user = _res.data.data;

        return _user;
      } catch (error) {
        console.log("Error getGuest", error);
        throw error;
      }
    };

    async function setPointsOnMicePlus(internalCode = '') {
      try {
        await rankingService.setAdminPlusRankingPoints(
          'ACESSO',
          false,
          internalCode,
          '50acc980-5b59-48b6-bca1-c33e713827cf'
        );
      } catch (error) {
        console.log('Error setAdminPlusRankingPoints', error);
      }
    }
  },

  async createToken(userId = '', email = '') {
    try {
      const options = {
        url: `${apiService.getUrl().hallAuthenticationApi}user/createToken`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userId: userId,
          email: email,
        },
      };

      let _res = await axios(options);

      return _res.data.data;
    } catch (error) {
      console.log('Error createToken');
      throw error;
    }
  }
}
