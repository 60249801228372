import React, { useState } from "react";
import { connect } from "react-redux";
import { getDic } from "../assets/i18n/dictionary";
import { addNewUserImageApiAvatar } from "../services/fileService";
import { userUpdate, userLoginSuccess } from "../store/actions";
import * as navigationHistoryService from '../services/navigationHistoryService';
import * as rankingService from '../services/rankingService';
//Imports de components
import { BackButton, Loading } from "../components";

const { Avataaars } = require("./AvatarLibrary");

const AnimeCard = (props) => {
  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(
    getDic("salvando")
  );
  const [openLoading, setOpenLoading] = React.useState(false);

  const [gender, setGender] = useState("F");
  const [skinColor, setSkinColor] = useState("Light");
  const [top, setTop] = useState("LongHairStraight");
  const [topM, setTopM] = useState("ShortHairShortRound");
  const [accessories, setAccessories] = useState("Blank");
  const [hairColor, setHairColor] = useState("BrownDark");
  const [facialHair, setFacialHair] = useState("Blank");
  const [facialHairColor, setFacialHairColor] = useState("BlondeGolden");
  const [clothes, setClothes] = useState("BlazerShirt");
  const [clothesColor, setClothesColor] = useState("Black");
  const [eyes, setEyes] = useState("Default");
  const [eyebrow, setEyebrow] = useState("Default");
  const [mouth, setMouth] = useState("Default");

  const closeModal = () => {
    if (props.closeModal) {
      props.closeModal();
    }
  };

  React.useEffect(() => { });
  function salvarAvatar() {
    setOpenLoading(true);
    var s = new XMLSerializer().serializeToString(
      document.getElementById("avatar_preview")
    );

    addNewUserImageApiAvatar(`data:image/svg+xml;base64,${window.btoa(s)}`)
      .then(async (res) => {
        var newUser = props.user;
        newUser.image = res;
        await props.userLoginSuccess(newUser);

        try {
          await rankingService.setAdminPlusRankingPoints(
            'AVATAR',
            false,
            newUser.internalCode,
            '50acc980-5b59-48b6-bca1-c33e713827cf'
          );
        } catch (error) {
          console.log('Error setAdminPlusRankingPoints', error);
        }

        if (props.isModal) {
          closeModal();
        } else {
          navigationHistoryService.goBackHistory(props.history);
        }
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  return (
    <div class="full">
      {!props.isModal && (
        <>
          <BackButton history={props.history} color="#212121">
            {getDic("voltar").toUpperCase()}
          </BackButton>
        </>
      )}
      <div className="box-avatar">
        <h1> {getDic("create-avatar")}</h1>
        <div className="avatar-creation-main-box">
          <div className="box-avatar__img">
            {gender === "F" && (
              <div
                dangerouslySetInnerHTML={{
                  __html: Avataaars.create({
                    width: 200,
                    height: 200,
                    eyes: eyes,
                    clothing: clothes,
                    clothingColor: clothesColor,
                    top: top,
                    hairColor: hairColor,
                    accessories: accessories,
                    facialHair: facialHair,
                    facialHairColor: facialHairColor,
                    mouth: mouth,
                    skin: skinColor,
                    eyebrows: eyebrow,
                  }),
                }}
              />
            )}
            {gender === "M" && (
              <div
                dangerouslySetInnerHTML={{
                  __html: Avataaars.create({
                    width: 200,
                    height: 200,
                    eyes: eyes,
                    clothing: clothes,
                    clothingColor: clothesColor,
                    top: top,
                    hairColor: hairColor,
                    accessories: accessories,
                    facialHair: facialHair,
                    facialHairColor: facialHairColor,
                    mouth: mouth,
                    skin: skinColor,
                    eyebrows: eyebrow,
                  }),
                }}
              />
            )}

            <canvas id="myCanvas" />

            <div className="opcoes-cores">
              <div
                className={
                  skinColor == "tanned" ? "tanned opcao-ativa" : "tanned"
                }
                onClick={() => {
                  setSkinColor("tanned");
                }}
              />
              <div
                className={
                  skinColor == "yellow" ? "yellow opcao-ativa" : "yellow"
                }
                onClick={() => {
                  setSkinColor("yellow");
                }}
              />
              <div
                className={skinColor == "pale" ? "pale opcao-ativa" : "pale"}
                onClick={() => {
                  setSkinColor("pale");
                }}
              />
              <div
                className={skinColor == "light" ? "light opcao-ativa" : "light"}
                onClick={() => {
                  setSkinColor("light");
                }}
              />
              <div
                className={skinColor == "brown" ? "brown opcao-ativa" : "brown"}
                onClick={() => {
                  setSkinColor("brown");
                }}
              />
              <div
                className={
                  skinColor == "darkBrown"
                    ? "darkbrown opcao-ativa"
                    : "darkbrown"
                }
                onClick={() => {
                  setSkinColor("darkBrown");
                }}
              />
              <div
                className={skinColor == "black" ? "black opcao-ativa" : "black"}
                onClick={() => {
                  setSkinColor("black");
                }}
              />
            </div>
          </div>

          <div className="selects">
            {/* <div className="gender-box">
              <label>{getDic("gender")}</label>
              <select
                onChange={(e) => {
                  setGender(e.target.value);
                }}
              >
                <option value="F">{getDic("feminine")}</option>
                <option value="M">{getDic("male")}</option>
              </select>
            </div> */}
            <div>
              <div>
                <div>
                  <label>{getDic("hair")}</label>
                  <select
                    className="new_grade"
                    defaultValue={"LongHairStraight"}
                    onChange={(e) => {
                      setTop(e.target.value);
                    }}
                  >
                    <option value="none">{getDic("no-hair")}</option>
                    <option value="eyepatch">{getDic("eyepatch")}</option>
                    <option value="hat">{getDic("hat")}</option>
                    <option value="hijab">Hijab</option>
                    <option value="turban">{getDic("turban")}</option>
                    <option value="winterHat01">
                      {getDic("winter-hat")} 1
                    </option>
                    <option value="winterHat02">
                      {getDic("winter-hat")} 2
                    </option>
                    <option value="winterHat03">
                      {getDic("winter-hat")} 3
                    </option>
                    <option value="winterHat04">
                      {getDic("winter-hat")} 4
                    </option>
                    <option value="dreads01">
                      {getDic("short-hair-dread")} 1
                    </option>
                    <option value="dreads02">
                      {getDic("short-hair-dread")} 2
                    </option>
                    <option value="frizzle">
                      {getDic("short-hair-frizzle")}
                    </option>
                    <option value="shaggyMullet">
                      {getDic("short-hair-shaggy-mullet")}
                    </option>
                    <option value="shortCurly">
                      {getDic("short-hair-short-curly")}
                    </option>
                    <option value="shortFlat">
                      {getDic("short-hair-short-flat")}
                    </option>
                    <option value="shortRound">
                      {getDic("short-hair-short-round")}
                    </option>
                    <option value="shortWaved">
                      {getDic("short-hair-short-waved")}
                    </option>
                    <option value="sides">{getDic("short-hair-sides")}</option>
                    <option value="theCaesar">
                      {getDic("short-hair-the-caesar")}
                    </option>
                    <option value="theCaesarAndSidePart">
                      {getDic("short-hair-the-caesar-side-part")}
                    </option>
                    <option value="bigHair">
                      {getDic("long-hair-big-hair")}
                    </option>
                    <option value="bob">{getDic("long-hair-bob")}</option>
                    <option value="bun"> {getDic("long-hair-bun")}</option>
                    <option value="curly"> {getDic("long-hair-curly")}</option>
                    <option value="curvy"> {getDic("long-hair-curvy")}</option>
                    <option value="dreads">
                      {" "}
                      {getDic("long-hair-dreads")}
                    </option>
                    <option value="frida"> {getDic("long-hair-frida")}</option>
                    <option value="fro"> {getDic("long-hair-fro")}</option>
                    <option value="froAndBand">
                      {" "}
                      {getDic("long-hair-fro-band")}
                    </option>
                    <option value="longButNotTooLong">
                      {getDic("long-hair-not-too-long")}
                    </option>
                    <option value="shavedSides">
                      {getDic("long-hair-shaved-sides")}
                    </option>
                    <option value="miaWallace">
                      {getDic("long-hair-mia-wallace")}
                    </option>
                    <option value="straight01">
                      {getDic("long-hair-straight")} 1
                    </option>
                    <option value="straight02">
                      {getDic("long-hair-straight")} 2
                    </option>
                    <option value="straight03">
                      {getDic("long-hair-straight-strand")}
                    </option>
                  </select>
                </div>
                <div>
                  <label>{getDic("hair-color")}</label>
                  <select
                    onChange={(e) => {
                      setHairColor(e.target.value);
                    }}
                    id="hairColor"
                    class="form-control"
                  >
                    <option value="auburn"> {getDic("auburn")}</option>
                    <option value="black"> {getDic("black")}</option>
                    <option value="blonde"> {getDic("blonde")}</option>
                    <option value="blondeGolden">
                      {" "}
                      {getDic("blonde-golden")}
                    </option>
                    <option value="brown">{getDic("brown")}</option>
                    <option value="brownDark">{getDic("brown-dark")}</option>
                    <option value="pastelPink">{getDic("pastel-pink")}</option>
                    <option value="platinum">{getDic("platinum")}</option>
                    <option value="red">{getDic("red")}</option>
                    <option value="silverGray">{getDic("silver-gray")}</option>
                  </select>
                </div>
                <div>
                  <label>{getDic("accessories")}</label>
                  <select
                    id="accessoriesType"
                    className="form-control"
                    defaultValue={"Kurt"}
                    onChange={(e) => {
                      setAccessories(e.target.value);
                    }}
                  >
                    <option value="none">{getDic("blank")}</option>
                    <option value="kurt">{getDic("kurt")}</option>
                    <option value="prescription01">
                      {getDic("prescription")} 1
                    </option>
                    <option value="prescription02">
                      {getDic("prescription")} 2
                    </option>
                    <option value="round">{getDic("round")}</option>
                    <option value="sunglasses">{getDic("sunglasses")} 1</option>
                    <option value="wayfarers">{getDic("wayfarers")} 2</option>
                  </select>
                </div>
                {/* <div>
                    <label>{getDic("facial-hair")}</label>
                    <select
                      id="facialHairType"
                      onChange={(e) => {
                        setFacialHair(e.target.value);
                      }}
                      class="form-control"
                    >
                      <option value="Blank">{getDic("blank")}</option>
                      <option value="BeardMedium">
                        {getDic("beard-medium")}
                      </option>
                      <option value="BeardLight">
                        {getDic("beard-light")}
                      </option>
                      <option value="BeardMagestic">
                        {getDic("beard-magestic")}
                      </option>
                      <option value="MoustacheFancy">
                        {getDic("moustache-fancy")} 1
                      </option>
                      <option value="MoustacheFancy">
                        {getDic("moustache-fancy")} 2
                      </option>
                    </select>
                  </div>
                  <div>
                    <label> {getDic("facial-hair-color")}</label>
                    <select
                      onChange={(e) => {
                        setFacialHairColor(e.target.value);
                      }}
                      id="facialHairColor"
                      class="form-control"
                    >
                      <option value="Auburn">{getDic("auburn")}</option>
                      <option value="Black">{getDic("black")}</option>
                      <option value="Blonde">{getDic("blonde")}</option>
                      <option value="BlondeGolden">
                        {getDic("blonde-golden")}
                      </option>
                      <option value="Brown">{getDic("brown")}</option>
                      <option value="BrownDark">{getDic("brown-dark")}</option>
                      <option value="Platinum">{getDic("platinum")}</option>
                      <option value="Red">{getDic("red")}</option>
                    </select>
                  </div> */}
                <div>
                  <label>{getDic("clothes")}</label>
                  <select
                    onChange={(e) => {
                      setClothes(e.target.value);
                    }}
                    id="clotheType"
                    class="form-control"
                  >
                    <option value="blazerAndShirt">
                      {getDic("blazer-shirt")}
                    </option>
                    <option value="blazerAndSweater">
                      {getDic("blazer-sweater")}
                    </option>
                    <option value="collarAndSweater">
                      {getDic("collar-sweater")}
                    </option>
                    <option value="graphicShirt">
                      {getDic("graphic-shirt")}
                    </option>
                    <option value="hoodie">{getDic("hoodie")}</option>
                    <option value="overall">{getDic("overall")}</option>
                    <option value="shirtCrewNeck">
                      {getDic("shirt-crew-neck")}
                    </option>
                    <option value="shirtScoopNeck">
                      {getDic("shirt-scoop-neck")}
                    </option>
                    <option value="shirtVNeck">
                      {getDic("shirt-scoop-neck")}
                    </option>
                  </select>
                </div>
              </div>
              <div>
                <div>
                  <label> {getDic("clothes-color")}</label>
                  <select
                    onChange={(e) => {
                      setClothesColor(e.target.value);
                    }}
                    id="clotheColor"
                    class="form-control"
                  >
                    <option value="black">{getDic("black")}</option>
                    <option value="blue01">{getDic("blue")} 1</option>
                    <option value="blue02">{getDic("blue")} 2</option>
                    <option value="blue03">{getDic("blue")} 3</option>
                    <option value="gray01">{getDic("gray")} 1</option>
                    <option value="gray02">{getDic("gray")} 2</option>
                    <option value="heather">{getDic("heather")}</option>
                    <option value="pastelBlue">{getDic("pastel-blue")}</option>
                    <option value="pastelGreen">
                      {getDic("pastel-green")}
                    </option>
                    <option value="pastelOrange">
                      {getDic("pastel-orange")}
                    </option>
                    <option value="pastelRed">{getDic("pastel-red")}</option>
                    <option value="pastelYellow">
                      {getDic("pastel-yellow")}
                    </option>
                    <option value="pink">{getDic("pink")}</option>
                    <option value="red">{getDic("red")}</option>
                    <option value="white">{getDic("white")}</option>
                  </select>
                </div>
                <div>
                  <label>{getDic("eyes")}</label>
                  <select
                    onChange={(e) => {
                      setEyes(e.target.value);
                    }}
                    id="eyeType"
                    class="form-control"
                  >
                    <option value="close">{getDic("close")}</option>
                    <option value="cry">{getDic("cry")}</option>
                    <option value="default">{getDic("default")}</option>
                    <option value="dizzy">{getDic("dizzy")}</option>
                    <option value="eyeRoll">{getDic("eye-roll")}</option>
                    <option value="happy">{getDic("happy")}</option>
                    <option value="hearts">{getDic("hearts")}</option>
                    <option value="side">{getDic("side")}</option>
                    <option value="squint">{getDic("squint")}</option>
                    <option value="surprised">{getDic("surprised")}</option>
                    <option value="wink">{getDic("wink")}</option>
                    <option value="winkWacky">{getDic("wink-wacky")}</option>
                  </select>
                </div>
                <div>
                  <label>{getDic("eyebrow")}</label>
                  <select
                    onChange={(e) => {
                      setEyebrow(e.target.value);
                    }}
                    id="eyebrowType"
                    class="form-control"
                  >
                    <option value="angry">{getDic("angry")}</option>
                    <option value="angryNatural">
                      {getDic("angry-natural")}
                    </option>
                    <option value="default">{getDic("default")}</option>
                    <option value="defaultNatural">
                      {getDic("default-natural")}
                    </option>
                    <option value="flatNatural">
                      {getDic("flat-natural")}
                    </option>
                    <option value="raisedExcited">
                      {getDic("raised-excited")}
                    </option>
                    <option value="raisedExcitedNatural">
                      {getDic("raised-excited-natural")}
                    </option>
                    <option value="sadConcerned">
                      {" "}
                      {getDic("sad-concerned")}
                    </option>
                    <option value="sadConcernedNatural">
                      {getDic("sad-concerned-natural")}
                    </option>
                    <option value="unibrowNatural">
                      {getDic("unibrow-natural")}
                    </option>
                    <option value="upDown">{getDic("up-down")}</option>
                    <option value="upDownNatural">
                      {getDic("up-down-natural")}
                    </option>
                  </select>
                </div>
                <div>
                  <label> {getDic("mouth")}</label>
                  <select
                    onChange={(e) => {
                      setMouth(e.target.value);
                    }}
                    id="mouthType"
                    class="form-control"
                  >
                    <option value="concerned">{getDic("concerned")}</option>
                    <option value="default">{getDic("default")}</option>
                    <option value="disbelief">{getDic("disbelief")}</option>
                    <option value="eating">{getDic("eating")}</option>
                    <option value="grimace">{getDic("grimace")}</option>
                    <option value="sad">{getDic("sad")}</option>
                    <option value="screamOpen">{getDic("scream-open")}</option>
                    <option value="serious">{getDic("serious")}</option>
                    <option value="smile">{getDic("smile")}</option>
                    <option value="tongue">{getDic("tongue")}</option>
                    <option value="twinkle">{getDic("twinkle")}</option>
                    <option value="vomit">{getDic("vomit")}</option>
                  </select>
                </div>
              </div>
            </div>

            {/* <button
							onClick={() => {
								salvarAvatar(imageSrcF);
							}}
						>
							Salvar
						</button> */}

            {gender === "M" && (
              <button
                onClick={() => {
                  salvarAvatar();
                }}
              >
                {getDic("salvar")}
              </button>
            )}
            {gender === "F" && (
              <button
                onClick={() => {
                  salvarAvatar();
                }}
              >
                {getDic("salvar")}
              </button>
            )}
          </div>
        </div>
      </div>

      <Loading open={openLoading} message={loadingMessage} />
    </div>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
    config: state.config,
  };
}

const mapDispatchToProps = {
  userUpdate,
  userLoginSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnimeCard);
