import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getDic } from "../../assets/i18n/dictionary";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { firestore } from "../../utils/firebase";
import "./emoji-mart/emoji-mart.css";
import { Picker } from "emoji-mart";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { animateScroll } from "react-scroll";
import { insertHyperLink } from "../../utils/inputFieldMasks";
import {
  Dialog,
  Container,
  FabButton,
  Avatar,
  FormRow,
  FormColumn,
  TextBig,
  InputFieldOutlined,
  Button,
  TextSmall,
  Modal,
  Loading,
  Alert
} from '../';
import CameraIcon from '@material-ui/icons/AddAPhoto';
import FaceIcon from '@material-ui/icons/Face';
import { getFileExtension } from '../../utils';
import { addNewUserImageApi } from '../../services/fileService';
import { userLoginSuccess } from '../../store/actions';
import CreateAvatarPage from '../../pages/CreateAvatarPage';
import * as rankingService from '../../services/rankingService';

import "./Styles/index.scss";

const messageTreatment = (m) => {
  var text = m;
  if (
    text.includes("http://") ||
    text.includes("https://") ||
    text.includes("HTTPS://") ||
    text.includes("HTTP://")
  ) {
    text = insertHyperLink(text);
  }
  return (
    <div>
      <span fontSize="14px">{text}</span>
    </div>
  );
};

const getCurrentTime = (UNIX_timestamp) => {
  var a = new Date(UNIX_timestamp);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate() > 9 ? a.getDate() : "0" + a.getDate();
  var hour = a.getHours() > 9 ? a.getHours() : "0" + a.getHours();
  var min = a.getMinutes() > 9 ? a.getMinutes() : "0" + a.getMinutes();
  var sec = a.getSeconds() > 9 ? a.getSeconds() : "0" + a.getSeconds();
  var time =
    date + "  " + month + "  " + year + " - " + hour + ":" + min + ":" + sec;
  return time;
};

const ChatTransmissionV2 = (props) => {
  const msgLimit = 100;
  const dummy = useRef();
  const scrollUL = useRef();

  const [loadingControl, setLoadingControl] = React.useState({
    open: false,
    message: ''
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: ''
  });

  const [currentDate, setCurrentDate] = React.useState(1);
  const [currentPagination, setCurrentPagination] = React.useState(msgLimit);

  const currentPage = useRef(currentPagination);
  const currentTime = useRef(currentDate);
  const [msgError, setMsgError] = React.useState("");
  const [msgErrorTitle, setMsgErrorTitle] = React.useState("");
  const [lastMsgSend, setLastMsgSend] = React.useState("");
  const [lastMsgError, setLastMsgError] = React.useState(false);
  const messagesRef = firestore.collection(
    `messages/${props.event.id}/${props.chosenConfig.id}/public/msgs`
  );
  const query = messagesRef
    .orderBy("createdAt", "asc")
    .limitToLast(currentPage.current);
  const [messages] = useCollectionData(query, { idField: "id" });
  const [formValue, setFormValue] = useState("");
  const [openModalEmoji, setModalEmoji] = React.useState(false);
  const [modalChangeImage, setModalChangeImage] = React.useState({
    open: false,
    noAvatar: props.config.find(c => { return c.codeScreen === 'NO-AVATAR' })
  });
  const [modalNewImage, setModalNewImage] = React.useState({
    open: false,
    newImage: null
  });
  const [modalAvatar, setModalAvatar] = React.useState({
    open: false,
  });

  const [oldMsg, setOldMsg] = React.useState([]);
  const lastMsg = () => {
    let date;
    if (messages.length > 0) date = messages[messages.length - 1].startAt;
    else date = oldMsg[oldMsg.length - 1];
    return date;
  };
  const pedingApproval = () => {
    const _t = props.config.filter((e) => e.id == props.chosenConfig.id);
    if (_t[0].urlString.indexOf("chatPendingApproval") > -1) {
      setMsgError("chat-limit-msg");
      setLastMsgError(true);
      return true;
    } else {
      return false;
    }
  };

  const getCurrentPostTime = () =>
    parseInt(window.localStorage.getItem("chat-time"));
  const [countDown, setCountDown] = React.useState(10);

  const queryOldMsg = messagesRef
    .orderBy("createdAt", "asc") /* .endAt(new Date(currentTime.current)) */
    .limitToLast(currentPage.current);
  //const oldMessages = useCollectionDataOnce(queryOldMsg, {idField: 'id'});

  React.useEffect(() => {
    if (messages) scrollToBottom();
  }, [messages]);
  React.useEffect(() => {
    const timer =
      countDown > 0 && setInterval(() => setCountDown(countDown - 1), 1000);
    return () => clearInterval(timer);
  }, [countDown]);

  const loadOldMsg = (o) => {
    //setOldMsg(oldMessages[0]);
    if (oldMsg && oldMsg.length > 0) {
      let page = /* currentPagination +  */ msgLimit * 2;
      setCurrentPagination(page);
      currentPage.current = page;
    }
  };
  const scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: "ul_container",
      delay: 0,
      duration: 0,
    });
    //dummy.current.scrollIntoView();
  };

  const sendMessage = async (e) => {
    if (e) e.preventDefault();
    if (formValue == "") return;
    if (formValue.length > 500) return;
    if (formValue.indexOf("�") > -1) return;
    if (formValue.indexOf("◕") > -1) return;
    if (formValue.indexOf("╔") > -1) return;
    if (formValue.indexOf("☆") > -1) return;
    if (formValue.indexOf("█") > -1) return;
    if (formValue.indexOf("¶") > -1) return;
    if (formValue.indexOf("▒") > -1) return;
    if (formValue.indexOf("♥") > -1) return;
    //window.localStorage.setItem('chat-time', Date.now());
    let lastChatBody = window.localStorage.getItem("chat-body");
    let lastChatTime = getCurrentPostTime();
    if (lastChatBody && lastChatTime) {
      if (lastChatBody == formValue) {
        let dateMsg = new Date(lastChatTime);
        let dateCurrent = new Date(Date.now() - 30000);
        console.log(dateMsg, dateCurrent);
        if (dateCurrent < dateMsg) {
          setMsgError("chat-limit-msg");
          setLastMsgError(true);
          return;
        } else {
          setLastMsgError(false);
        }
      }
    }
    setCountDown(10);
    setFormValue("");
    window.localStorage.setItem("chat-time", Date.now());
    window.localStorage.setItem("chat-body", formValue);

    let tempMsg = {
      user: props.user.name,
      body: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid: props.user.id,
      photoURL: props.user.image,
      config: props.chosenConfig.id,
      disabled: pedingApproval(),
    };
    let checkApproval = pedingApproval();
    if (checkApproval) {
      setMsgErrorTitle("chat-pending-title");
      setMsgError("chat-pending-msg");
      setLastMsgError(true);
    } else {
      setLastMsgError(false);
    }
    await messagesRef.add(tempMsg);
    //if(dummy.current) scrollToBottom();
  };

  const onEmojiClick = (emojiObject, props) => {
    let sym = emojiObject.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);

    var message = `${formValue}${emoji}`;
    setFormValue(message);
    setModalEmoji(false);
  };

  const changeUserImage = () => {
    try {

    } catch (error) {

    }
  }

  return (
    <React.Fragment>
      <div className="general-container" style={{ height: "100%" }}>
        {openModalEmoji && (
          <div
            style={{
              position: "relative",
            }}
          >
            <i
              onClick={() => {
                setModalEmoji(false);
              }}
              className="fa fa-times-circle fa-2x"
              style={{
                position: "relative",
                left: "91%",
                top: -3,
                fontSize: 21,
                color: "#c38f1f",
                cursor: "pointer",
              }}
            />
            <Picker
              showPreview={false}
              showSkinTones={false}
              style={{
                position: "relative",
                zIndex: 199999,
                height: "auto",
                width: "100%",
              }}
              exclude={["flags"]}
              i18n={{
                search: getDic("search"),
                categories: {
                  search: getDic("search"),
                  recent: getDic("recente"),
                  smileys: getDic("smileys"),
                  people: getDic("people"),
                  nature: getDic("nature"),
                  foods: getDic("foods"),
                  activity: getDic("activity"),
                  places: getDic("places"),
                  objects: getDic("objects"),
                  symbols: getDic("symbols"),
                  custom: getDic("custom"),
                  flags: getDic("flags"),
                },
              }}
              onClick={(emoji, props) => {
                onEmojiClick(emoji, props);
              }}
            />
          </div>
        )}

        <div
          className="col-sm-12 col-sm-offset-12 frame "
          style={{
            overflow: "hidden",
            padding: 0,
            height: openModalEmoji ? 0 : "100%",
          }}
        >
          <ul className="fix-ul" id="ul_container">
            <li style={{ textAlign: "center" }}>
              {0 > 0 && (
                <i
                  class="fas fa-ellipsis-h"
                  style={{
                    background: "#888888",
                    color: "white",
                    paddingLeft: 7,
                    paddingRight: 7,
                    paddingTop: 1,
                    paddingBottom: 1,
                    borderRadius: 8,
                    cursor: "pointer",
                  }}
                  onClick={() => loadOldMsg()}
                ></i>
              )}
            </li>
            {messages &&
              messages
                .filter((f) => f.disabled == false)
                .map((msg) => <MeCard key={msg.id} message={msg} />)}
            <li ref={dummy} id="inScreenView"></li>
          </ul>
          <div
            style={{
              position: "absolute",
              marginTop: 0,
              bottom: 35,
              width: "auto",
              padding: 1,
              overflow: "hidden",
              height: lastMsgError ? "auto" : 0,
              transition: "1s",
            }}
          >
            <div
              style={{
                background: "whitesmoke",
                flexDirection: "row",
                display: "flex",
                padding: 6,
                margin: 4,
                borderRadius: 5,
              }}
            >
              <i
                class="fas fa-exclamation-triangle"
                style={{ color: "#ff9800", fontSize: 22 }}
              ></i>
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  paddingLeft: 15,
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span style={{ fontWeight: 600 }}>
                    {getDic(msgErrorTitle)}
                  </span>
                  <i
                    class="fas fa-times"
                    style={{
                      color: "#888888",
                      fontSize: 16,
                      position: "absolute",
                      right: 15,
                      top: 14,
                      cursor: "pointer",
                    }}
                    onClick={() => setLastMsgError(false)}
                  ></i>
                </div>
                <span style={{ marginTop: 10 }}>{getDic(msgError)}</span>
              </div>
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="macro"
              style={{ width: "100%", backgroundColor: "transparent" }}
            >
              <div
                className="text text-r"
                style={{ width: "100%", background: "whitesmoke !important" }}
              >
                <form onSubmit={sendMessage}>
                  <input
                    className="mytext"
                    placeholder={
                      getCurrentPostTime() > Date.now() - 10000
                        ? ""
                        : getDic("diga-algo") + '...'
                    }
                    disabled={
                      getCurrentPostTime() > Date.now() - 10000 ? true : false
                    }
                    value={formValue}
                    onChange={(e) => setFormValue(e.target.value)}
                    style={{ width: "100%", paddingRight: 24, paddingLeft: 60 }}
                    maxLength="500"
                  />
                  <div
                    style={{
                      position: "absolute",
                      left: 8,
                      top: 20,
                    }}
                  >
                    <div className="avatar" style={{ width: 23 }}>
                      <img
                        className="img-circle"
                        style={{
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer'
                        }}
                        src={
                          props.user.image
                            ? props.user.image
                            : "../../../avatar-public.png"
                        }
                        onClick={() => setModalChangeImage({ ...modalChangeImage, open: true })}
                      />
                    </div>
                    <i
                      onClick={() =>
                        setModalEmoji(
                          getCurrentPostTime() > Date.now() - 10000 ? false : true
                        )
                      }
                      className="fa fa-smile-o fa-2x"
                      style={{
                        fontSize: 21,
                        color: "#888888",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  {getCurrentPostTime() > Date.now() - 10000 && (
                    <div
                      style={{
                        position: "absolute",
                        top: 15,
                        left: "20%",
                        color: "#888888",
                      }}
                    >
                      <span>
                        {getDic("chat-espera-mensagem")} {countDown}s
                      </span>
                    </div>
                  )}
                  <i
                    onClick={() => sendMessage()}
                    className="fa fa-send fa-2x"
                    aria-hidden="true"
                    style={{
                      fontSize: 22,
                      position: "absolute",
                      right: 13,
                      top: 19,
                      color: "#888888",
                      cursor: "pointer",
                    }}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderModalChangeImage()}
      {renderModalUploadImage()}
      {renderModalUpdateAvatar()}

      <Alert
        open={alertControl.open}
        onClose={() => setAlertControl({ ...alertControl, open: false })}
        onClick={() => setAlertControl({ ...alertControl, open: false })}
        title={alertControl.title}
        message={alertControl.message}
      />

      <Loading open={loadingControl.open} message={loadingControl.message} />
    </React.Fragment>
  );

  function renderModalChangeImage() {
    return (
      <Dialog
        open={modalChangeImage.open}
        title={`${getDic("alterar")} ${getDic("imagem")}`}
        textButtonYes={getDic("sair")}
        onClickYes={() => setModalChangeImage({ ...modalChangeImage, open: false })}
        onClose={() => setModalChangeImage({ ...modalChangeImage, open: false })}
      >
        <Container background="#e8eced" align="left" height="100%">
          <div>
            <FormRow align="center">
              <Avatar src={props.user.image} size="big" />
            </FormRow>
            <div className="icons-profile" style={{ marginTop: '-45px' }}>
              {!modalChangeImage.noAvatar && (
                <a
                  className="link-create-avatar"
                  color="#e0e0e0"
                  textColor="black"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setModalAvatar({ ...modalAvatar, open: true })}
                >
                  <FaceIcon />
                </a>
              )}
              <FabButton
                color="#e0e0e0"
                textColor="black"
                onClick={() => setModalNewImage({ ...modalNewImage, open: true })}
              >
                <CameraIcon />
              </FabButton>
            </div>
          </div>
        </Container>
      </Dialog>
    )
  }

  function renderModalUploadImage() {
    const registerNewImage = async () => {
      try {
        if (!modalNewImage.newImage) {
          setAlertControl({
            open: true,
            title: getDic('erro'),
            message: getDic('arquivo-nao-selecionado-erro-upload')
          });
          return;
        }

        var _fileType = getFileExtension(modalNewImage.newImage);
        if (_fileType !== 'IMG' && _fileType !== 'JPEG' && _fileType !== 'JPG' && _fileType !== 'PNG') {
          setAlertControl({
            open: true,
            title: getDic('erro'),
            message: getDic('arquivo-erro-tipo')
          });
          return;
        }

        setLoadingControl({ open: true, message: getDic("salvando") })

        let _newImage = await addNewUserImageApi(modalNewImage.newImage);

        var _newUser = props.user;
        _newUser.image = _newImage;

        await props.userLoginSuccess(_newUser);

        try {
          await rankingService.setAdminPlusRankingPoints(
            'AVATAR',
            false,
            _newUser.internalCode,
            '50acc980-5b59-48b6-bca1-c33e713827cf'
          );
        } catch (error) {
          console.log('Error setAdminPlusRankingPoints', error);
        }

        setLoadingControl({ ...loadingControl, open: false });
        setModalNewImage({ ...modalNewImage, open: false, newImage: null });
        setModalChangeImage({ ...modalChangeImage, open: false });
        setAlertControl({
          open: true,
          title: '',
          message: getDic('enviado-sucesso')
        });

      } catch (error) {
        console.log('Error registerNewImage', error);
        setLoadingControl({ ...loadingControl, open: false });
        setAlertControl({
          open: true,
          title: getDic('erro'),
          message: getDic('enviado-erro')
        });
      }
    }

    return (
      <Modal
        disableFullScreen
        isVideo
        open={modalNewImage.open}
        closeButtonClick={() => setModalNewImage({ ...modalNewImage, open: false, newImage: null })}
        onClose={() => setModalNewImage({ ...modalNewImage, open: false, newImage: null })}
      >
        <Container background="#e8eced" align="left" height="100%">
          <FormColumn>
            <FormRow align="center">
              <TextBig>Upload</TextBig>
            </FormRow>
            <FormRow align="center">
              <div style={{ maxWidth: '400px', marginRight: '10px' }}>
                <InputFieldOutlined
                  key="file"
                  type="file"
                  title={getDic('arquivo')}
                  placeholder={getDic('arquivo')}
                  onChange={(e) => setModalNewImage({ ...modalNewImage, newImage: e.target.files[0] })}
                />
              </div>
              <Button text={getDic('enviar')} onClick={() => registerNewImage()} />
            </FormRow>
            <FormRow align="center">
              <TextSmall>{getDic('formatos-permitidos')}: img, jpeg, jpg, png</TextSmall>
            </FormRow>
          </FormColumn>
        </Container>
      </Modal>
    )
  }

  function renderModalUpdateAvatar() {
    return (
      <Modal
        disableFullScreen
        isVideo
        open={modalAvatar.open}
        closeButtonClick={() => setModalAvatar({ ...modalAvatar, open: false })}
        onClose={() => setModalAvatar({ ...modalAvatar, open: false })}
      >
        <CreateAvatarPage isModal closeModal={() => {
          setModalAvatar({ ...modalAvatar, open: false });
          setModalChangeImage({ ...modalChangeImage, open: false });
        }} />
      </Modal>
    )
  }
};

const MeCard = (props) => {
  return (
    <li style={{ width: "100%" }}>
      <div
        className="msj-rta macro"
        style={{
          ...{
            backgroundColor: "white",
          },
          ...{
            paddingBottom: 10,
            flexDirection: "column",
          },
        }}
      >
        <div
          className="row-header"
          style={{
            flexDirection: "row",
            position: "relative",
          }}
        >
          <div
            className="text text-r"
            style={{ width: "100%", flexDirection: "row" }}
          >
            <div className="avatar">
              <img
                className="img-circle"
                src={
                  props.message.photoURL
                    ? props.message.photoURL
                    : "../../../avatar-public.png"
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 900,
                }}
              >
                {props.message.user} -
                <span
                  style={{
                    color: "#bdbdbd",
                    fontSize: 12,
                    fontWeight: 900,
                  }}
                >
                  {props.message.createdAt &&
                    getCurrentTime(props.message.createdAt.toDate())}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div
          className="text text-r"
          style={{
            wordWrap: "break-word",
          }}
        >
          <span
            style={{
              marginLeft: 43,
            }}
          >
            {messageTreatment(props.message.body)}
          </span>
          <p>
            <small></small>
          </p>
        </div>
      </div>
    </li>
  );
};

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
    config: state.config,
  };
}

const mapDispatchToProps = {
  userLoginSuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatTransmissionV2);
