import React from "react";
import LoginPageContext from "./LoginPageContext";
import { store } from "../../store";
import { getDic, getLanguages } from "../../assets/i18n/dictionary";
import { createFormControlModel } from "../../models/formControlModel";
import { connect } from "react-redux";
import { validateForm, validatePassword } from "../../utils";
import { getDate } from "../../services/apiService";
import {
  userLoginSuccess,
  setLanguage,
  resetAllAccess,
  resetNotifications,
  userLogout,
  resetChosenPavilion,
  resetPrivateChatControl,
  resetHistory,
} from "../../store/actions";
import {
  getUserByEmailOrCpfOrInternalCode,
  setNewUserPasswordApi,
  changeUserTerms,
  updateUserApi,
  saveUserAccess,
  validateLoginByEventUserAndToken,
  setForgotPasswordRoutine,
} from "../../services/userService";
import { syncData } from "../../services/syncDataService";
import * as checkinService from "../../services/checkinService";
import * as rankingService from "../../services/rankingService";
import { validateEmail } from "../../utils/inputFieldMasks";
import { setNewTheme } from "../../services/themeService";
import { createThemeModel } from "../../models/themeModel";
import { forceSendAccessReport } from "../../threads/accessThread";
import { initGoogleAnalytics } from "../../services/eventService";
import "../../scss/Login.scss";
import moment from "moment";
import { isMobile } from "react-device-detect";
import * as navigationHistoryService from "../../services/navigationHistoryService";
import { getAttributes } from "../../services/attributesService";
import { getCountOnlineGuests } from "../../services/guestService";
import blipChatService from "../../services/blipChatService";
import ssoMicrosoftService from "../../services/ssoMicrosoftService";

const LoginPageProvider = (props) => {
  var params = [];
  var eventID = null;
  if (eventID) {
    params.push(eventID);
  } else if (props.params && props.params.id) {
    params.push(props.params.id.split("&"));
  } else {
    params.push(process.env.REACT_APP_EVENT_ID);
  }

  //ID EVENTO, EVENTOID EVENTO ID
  const [state, setState] = React.useState(true);
  const [eventId, setEventId] = React.useState(137624);
  const [isAdmin, setIsAdmin] = React.useState(
    window.location.href.indexOf("?admin") > -1 ? true : false
  );
  const [isStaff, setIsStaff] = React.useState(
    window.location.href.indexOf("?staff") > -1 ? true : false
  );
  const [isDeveloper, setIsDeveloper] = React.useState(
    window.location.href.indexOf("?dev") > -1 ? true : false
  );
  const [blockAccess, setBlockAccess] = React.useState({
    blocked: false,
    url: "",
    url2: "",
  });
  const [hasEvent, setHasEvent] = React.useState(true);
  const [hasLang, setHasLang] = React.useState(false);
  const [accessForm, setAccessForm] = React.useState({
    name: "",
    lastName: "",
    email: "",
  });
  const [useRecaptcha, setUseRecaptcha] = React.useState(false);
  const [recaptchaVerify, setRecaptchaVerify] = React.useState();

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(
    getDic("carregando")
  );
  const [openLoading, setOpenLoading] = React.useState(false);

  //Controle de senha
  const [hasPasswordUser, setHasPasswordUser] = React.useState(false);
  const [passwordUser, setPasswordUser] = React.useState("");

  const [hasFirstAccessPolicy, setHasFirstAccessPolicy] = React.useState(false);
  const [hasRecoverPassword, setHasRecoverPassword] = React.useState(false);

  const [dialogForgotPasswordControl, setDialogForgotPasswordControl] =
    React.useState({
      open: false,
      waiting: false,
      showInsertToken: false,
    });
  const [forgotPasswordObject, setForgotPasswordObject] = React.useState({
    login: "",
    token: "",
    newPassword1: "",
    newPassword2: "",
  });
  const [isForgotPassword, setIsForgotPassword] = React.useState(false);
  const [safeLoginConfig, setSafeLoginConfig] = React.useState({
    isSafePassword: false,
    hasRecoverPassword: false,
    emailValidator: false,
    cpfMask: false,
    title: "Login",
    placeholder: "",
  });

  const [image001, setImage001] = React.useState(null);
  const [image003, setImage003] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [language, setLanguage] = React.useState(null);
  const [languages, setLanguages] = React.useState(null);

  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  const [imageBackgroundUrl, setImageBackgroundUrl] = React.useState(null);
  const [colorBackGround, setColorBackGround] = React.useState("#e8eced");
  const [primaryTitle, setPrimaryTitle] = React.useState({
    text:
      getDic("bem-vindo").toUpperCase() +
      " " +
      getDic("ao").toUpperCase() +
      " " +
      getDic("nosso").toUpperCase() +
      " " +
      getDic("evento").toUpperCase(),
    color: "black",
    size: "25px",
    default: true,
  });
  const [secondaryTitle, setSecondaryTitle] = React.useState({
    text: getDic("bem-vindo-mensagem").toUpperCase(),
    color: "black",
    size: "12px",
    default: true,
  });
  const [isCpf, setIsCpf] = React.useState(false);
  const [isInternalCode, setIsInternalCode] = React.useState(false);
  const [internalCodeText, setInternalCodeText] = React.useState("");
  const [hasRegister, setHasRegister] = React.useState(true);
  const [cardConfig, setCardConfig] = React.useState(null);
  const [button1, setButton1] = React.useState({
    text: getDic("acessar"),
    color: "#3f51b5",
    textColor: "white",
    default: true,
  });
  const [button2, setButton2] = React.useState({
    text: getDic("nao-possui-cadastro"),
    color: "#e0e0e0",
    textColor: "black",
    default: true,
  });
  const [button3, setButton3] = React.useState({
    text: getDic("primeiro") + " " + getDic("acesso") + "?",
    color: "#e0e0e0",
    textColor: "black",
    default: true,
  });
  const [button4, setButton4] = React.useState({
    text: getDic("esqueceu-a-senha"),
    color: "black",
    textColor: "white",
    default: true,
  });
  const [button5, setButton5] = React.useState({
    text: "",
    color: "black",
    textColor: "white",
    active: false,
    url: "",
    newTab: "false",
    default: true,
  });
  const [checkAuthorization, setCheckAuthorization] = React.useState(false);
  const [terms, setTerms] = React.useState(null);
  const [isComboboxTerms, setIsComboboxTerms] = React.useState(false);
  const [isIframeTerms, setIsIframeTerms] = React.useState(false);
  const [autoOpenTerms, setAutoOpenTerms] = React.useState(false);
  const [openModalTerms, setOpenModalTerms] = React.useState();
  const [termsCheckboxDisabled, setTermsCheckboxDisabled] =
    React.useState(false);
  const [tittle004, setTittle004] = React.useState({
    text: getDic("termos-mensagem"),
    color: "black",
    size: "16px",
    default: true,
  });
  const [tittle003, setTittle003] = React.useState({
    text: getDic("ler") + " " + getDic("termos"),
    color: "black",
    size: "16px",
    default: true,
  });
  const [useInputAdornments, setUseInputAdornments] = React.useState(false);
  const [useNameInForm, setUseNameInForm] = React.useState(true);
  const [useLastName, setUseLastName] = React.useState(false);
  const [updateUserName, setUpdateUserName] = React.useState(false);
  const [loginByEmailAndInternalCode, setLoginByEmailAndInternalCode] =
    React.useState(false);
  const [useInternalCodeHasPassword, setUseInternalCodeHasPassword] =
    React.useState(false);
  const [google, setGoogle] = React.useState();
  const [facebook, setFacebook] = React.useState();
  const [microsoftSso, setMicrosoftSso] = React.useState();
  const [enableTwoFactorLogin, setEnableTwoFactorLogin] = React.useState(false);
  const [userObjectTwoFactorLogin, setUserObjectTwoFactorLogin] =
    React.useState();
  const [openModalTwoFactorLogin, setOpenModalTwoFactorLogin] =
    React.useState(false);
  const [twoFactorLoginToken, setTwoFactorLoginToken] = React.useState("");
  const [showModalAche, setShowModalAche] = React.useState(false);
  const [openModalAche, setOpenModalAche] = React.useState(true);
  const [loginAccessLimit, setLoginAccessLimit] = React.useState(0);

  React.useEffect(() => {
    getPageData();
    addEventListeners();
    return () => { };
  }, []);

  const addEventListeners = () => {
    if (isMobile) {
      window.addEventListener("resize", () => {
        changeBackgroundImageMobile();
      });
    }
  };

  const changeBackgroundImageMobile = () => {
    if (isMobile) {
      const _backgroundImage = store.getState().config.find((c) => {
        return c.codeScreen === "IMAGE-002" && c.screenId === 4;
      });
      const _backgroundImageMobile = store.getState().config.find((c) => {
        return c.codeScreen === "IMAGE-002-MOBILE" && c.screenId === 4;
      });

      if (
        _backgroundImage &&
        _backgroundImage.url &&
        _backgroundImageMobile &&
        _backgroundImageMobile.url[0]
      ) {
        if (window.innerWidth < window.innerHeight) {
          setImageBackgroundUrl(_backgroundImageMobile.url[0]);
        } else {
          setImageBackgroundUrl(_backgroundImage.url[0]);
        }
      }
    }
  };

  const getPageData = async () => {
    setTimeout(async () => {
      //Reseta o state
      await props.resetChosenPavilion();
      await props.resetAllAccess();
      await props.resetNotifications();
      await props.userLogout();
      await props.resetPrivateChatControl();
      await props.resetHistory();
      syncData(eventId)
        .then(async () => {
          //showZendesk();
          var configList = store.getState().config;
          const _event = store.getState().event;

          //Caso tenha alterações de configurações de cor
          const backgroundColor = configList.find((item) => {
            return item.codeScreen === "BACKGROUND-COLOR";
          });
          const backgroundImage = configList.find((item) => {
            return item.codeScreen === "BACKGROUND-IMAGE";
          });
          if (backgroundColor || backgroundImage) {
            var color =
              backgroundColor && backgroundColor.url
                ? backgroundColor.url[0]
                : "#212121";
            var image =
              backgroundImage && backgroundImage.url
                ? backgroundImage.url[0]
                : "";

            var theme = createThemeModel(color, image);
            setNewTheme(theme);
          } else {
            var theme = createThemeModel();
            setNewTheme(theme);
          }

          //Ativar Login Microsoft
          const ssoMicrosoft = configList.find((item) => {
            return item.codeScreen === "USE-SSO-MICROSOFT";
          });
          if (ssoMicrosoft) {
            setMicrosoftSso(true);
            await ssoMicrosoftService.initialize(ssoMicrosoft);
          }

          //Ativar Login Google
          const googleBTN = store.getState().config.find((item) => {
            return item.codeScreen == "SSO_GOOGLE";
          });
          //Ativar Login Facebook
          const facebookBTN = store.getState().config.find((item) => {
            return item.codeScreen == "SSO_FACEBOOK";
          });
          //Checa os Sign Single On
          if (googleBTN) setGoogle(true);
          else setGoogle(false);
          if (facebookBTN) setFacebook(true);
          else setFacebook(false);
          //Ativar vlibras caso exista a config
          const vlibras = store.getState().config.find((item) => {
            return item.codeScreen === "USE-VLIBRAS";
          });
          if (vlibras) {
            let helpButton = document.getElementById("vlibras");
            if (helpButton) {
              helpButton.style.display = "block";
            }
          } else {
            let helpButton = document.getElementById("vlibras");
            if (helpButton) {
              helpButton.style.display = "none";
            }
          }
          //Ativar chat do blip
          let _blipChat = store.getState().config.find((item) => {
            return item.codeScreen === "USE-BLIP-CHAT";
          });
          if (_blipChat && window?.location?.href?.includes(_blipChat.eventId)) {
            blipChatService.init(_blipChat);
          }

          var lang = configList.find((item) => {
            return item.codeScreen === "SET-LANGUAGE";
          });
          if (lang) {
            await props.setLanguage({ lang: lang.url[0] });
          } else {
            await props.setLanguage({ lang: "pt-br" });
          }

          // Reseta textos com a linguagem correta
          setLanguageTexts();

          // var blockAccess = false;
          var blockAccess = configList.find((item) => {
            return item.codeScreen === "BLOCK-ACCESS";
          });
          const _currentDate = await getDate();
          var _endDate = moment(_event.endDate).add(1, "days").format();
          if (
            (blockAccess || _currentDate > _endDate) &&
            !isAdmin &&
            !isStaff &&
            !isDeveloper
          ) {
            var blockAccessUrl =
              blockAccess &&
                blockAccess.url[0] &&
                blockAccess.url[0] !== "" &&
                blockAccess.url[0] !== "#"
                ? blockAccess.url[0].split(",")
                : "";

            setBlockAccess({
              blocked: true,
              url: blockAccessUrl[0],
              url2:
                blockAccess && blockAccess.multiConfig
                  ? blockAccess.multiConfig.cardBackgroundImage
                  : "",
            });
            setColorBackGround(blockAccessUrl !== "" ? "#ffffff00" : "#e8eced");
          } else {
            checkIfLoginByNameAndEmailFromParams();
            configList.find((item) => {
              const redirect = isAdmin || isStaff ? false : true;
              if (
                item.codeScreen === "REDIRECT-PAGE" &&
                item.screenId === 4 &&
                redirect
              ) {
                navigationHistoryService.setHistory(
                  props.history,
                  "/" + eventId + "/registration"
                );
              }

              if (item.codeScreen === "IMAGE-001" && item.screenId === 4) {
                setImage001(item.url);
              }

              if (item.codeScreen === "IMAGE-002" && item.screenId === 4) {
                setImageBackgroundUrl(item.url[0]);
                setColorBackGround("#ffffff00");
              }

              if (item.codeScreen === "IMAGE-003" && item.screenId === 4) {
                setImage003(item.url[0]);
              }

              if (item.codeScreen === "TITTLE-001" && item.screenId === 4) {
                var newObj = primaryTitle;
                newObj.default = false;
                if (item.tooltip && item.tooltip !== "") {
                  newObj.text = item.tooltip;
                } else {
                  newObj.text = "";
                }

                if (item.url.length > 0) {
                  item.url.forEach((item) => {
                    var conf = item.split(":");
                    newObj[conf[0]] =
                      conf[0] === "size" ? conf[1] + "px" : conf[1];
                  });
                }
                setPrimaryTitle(newObj);
              }

              if (item.codeScreen === "TITTLE-002" && item.screenId === 4) {
                var newObj = secondaryTitle;
                newObj.default = false;
                if (item.tooltip && item.tooltip !== "") {
                  newObj.text = item.tooltip;
                } else {
                  newObj.text = "";
                }

                if (item.url.length > 0) {
                  item.url.forEach((item) => {
                    var conf = item.split(":");
                    newObj[conf[0]] =
                      conf[0] === "size" ? conf[1] + "px" : conf[1];
                  });
                }
                setSecondaryTitle(newObj);
              }

              if (item.codeScreen === "BUTTON-001" && item.screenId === 4) {
                var _newButton = button1;
                _newButton.text =
                  item.tooltip && item.tooltip !== ""
                    ? item.tooltip
                    : _newButton.text;
                _newButton.default =
                  item.tooltip && item.tooltip !== "" ? false : true;

                if (item.url.length > 0 && item.url !== "#") {
                  item.url.forEach((conf) => {
                    var _prop = conf.split(":");
                    _newButton[_prop[0]] = _prop[1]
                      ? _prop[1]
                      : _newButton[_prop[0]];
                  });
                }
                setButton1(_newButton);
              }

              if (item.codeScreen === "BUTTON-002" && item.screenId === 4) {
                var _newButton = button2;
                _newButton.text =
                  item.tooltip && item.tooltip !== ""
                    ? item.tooltip
                    : _newButton.text;
                _newButton.default =
                  item.tooltip && item.tooltip !== "" ? false : true;

                if (item.url.length > 0 && item.url !== "#") {
                  item.url.forEach((conf) => {
                    var _prop = conf.split(":");
                    _newButton[_prop[0]] = _prop[1]
                      ? _prop[1]
                      : _newButton[_prop[0]];
                  });
                }
                setButton2(_newButton);
              }

              if (item.codeScreen === "BUTTON-003" && item.screenId === 4) {
                var _newButton = button3;
                _newButton.text =
                  item.tooltip && item.tooltip !== ""
                    ? item.tooltip
                    : _newButton.text;
                _newButton.default =
                  item.tooltip && item.tooltip !== "" ? false : true;

                if (item.url.length > 0 && item.url !== "#") {
                  item.url.forEach((conf) => {
                    var _prop = conf.split(":");
                    _newButton[_prop[0]] = _prop[1]
                      ? _prop[1]
                      : _newButton[_prop[0]];
                  });
                }
                setButton3(_newButton);
              }

              if (item.codeScreen === "BUTTON-004" && item.screenId === 4) {
                var _newButton = button4;
                _newButton.text =
                  item.tooltip && item.tooltip !== ""
                    ? item.tooltip
                    : _newButton.text;
                _newButton.default =
                  item.tooltip && item.tooltip !== "" ? false : true;

                if (item.url.length > 0 && item.url !== "#") {
                  item.url.forEach((conf) => {
                    var _prop = conf.split(":");
                    _newButton[_prop[0]] = _prop[1]
                      ? _prop[1]
                      : _newButton[_prop[0]];
                  });
                }
                setButton4(_newButton);
              }

              if (item.codeScreen === "BUTTON-005" && item.screenId === 4) {
                var _buttonConfig = button5;
                try {
                  if (item.url.length > 0) {
                    item.url.forEach((item) => {
                      var conf = item.split(/:(.+)/); // Fazer split apenas no primeiro :
                      _buttonConfig[conf[0]] = conf[1];
                    });
                  }
                  _buttonConfig.text = item.tooltip;
                  _buttonConfig.active = true;
                  _buttonConfig.default = false;
                  setButton5(_buttonConfig);
                } catch (error) {
                  errorTreatment(
                    `Erro BUTTON-005 config ID: ${item.id}`,
                    error
                  );
                }
              }

              if (item.codeScreen === "USE-CPF" && item.screenId === 4) {
                setIsCpf(true);
              }

              if (
                item.codeScreen === "USE-INTERNAL-CODE" &&
                item.screenId === 4 &&
                !isAdmin
              ) {
                setIsInternalCode(true);
                setInternalCodeText(
                  item.tooltip && item.tooltip !== ""
                    ? item.tooltip
                    : getDic("senha")
                );
                item.url.forEach((element) => {
                  if (element === "useAsPassword:true") {
                    setLoginByEmailAndInternalCode(true);
                    setUseInternalCodeHasPassword(true);
                  }
                  if (element === "useEmail:true") {
                    setLoginByEmailAndInternalCode(true);
                  }
                });
              }

              if (item.codeScreen === "NO-REGISTER") {
                setHasRegister(false);
              }

              if (
                item.codeScreen === "CARD-SIMP" ||
                (item.codeScreen === "CARD-TRAN" && item.screenId === 4)
              ) {
                setCardConfig(item.codeScreen);
              }

              //Controle de políticas de acesso
              if (item.codeScreen === "FIRST-ACCESS-BUTTON") {
                setHasPasswordUser(true);
                setHasFirstAccessPolicy(true);
              }
              if (item.codeScreen === "HAS-PASSWORD" && item.screenId === 4) {
                var _hasRecoverPassword = true;
                if (item.url.includes("recoverPassword:false")) {
                  _hasRecoverPassword = false;
                }
                setHasPasswordUser(true);
                setHasRecoverPassword(_hasRecoverPassword);
              }

              if (item.codeScreen === "USE-ACCESS-BY-LOGIN-PASSWORD") {
                let _safeLoginConfig = safeLoginConfig;
                const _array = item.urlString.split(";");
                _array.forEach((p) => {
                  let _prop = p.split(":");
                  if (_prop[0] && _prop[1]) {
                    _safeLoginConfig[_prop[0].trim()] =
                      _prop[1].trim() === "true" ? true : _prop[1].trim();
                  }
                });

                setSafeLoginConfig({
                  ..._safeLoginConfig,
                  isSafePassword: true,
                });
              }

              if (item.codeScreen === "ENABLE-CHANGE-LANGUAGE") {
                setHasLang(true);
                setLanguages(getLanguages());
              }

              if (item.codeScreen === "USE-RECAPTCHA") {
                setUseRecaptcha(true);
              }

              if (item.codeScreen === "TERM-001" && item.screenId === 4) {
                var isIframe = false;
                var terms =
                  item.tooltip && item.tooltip !== "" ? item.tooltip : "";
                if (item.typeId === 3) {
                  isIframe = true;
                  terms = item.url[0];
                }
                setTerms(terms);
                setIsIframeTerms(isIframe);
              }

              if (item.codeScreen === "TERM-001-COMBOBOX") {
                setIsComboboxTerms(true);
                setCheckAuthorization(true);
              }

              if (item.codeScreen === "AUTO-OPEN-TERMS") {
                setAutoOpenTerms(true);
              }

              if (item.codeScreen === "READ-TERMS-REQUIRED") {
                setTermsCheckboxDisabled(true);
              }

              if (item.codeScreen === "TITTLE-003" && item.screenId === 4) {
                var newObj = tittle003;
                if (item.tooltip && item.tooltip !== "") {
                  newObj.text = item.tooltip;
                  newObj.default = false;
                }

                if (item.url.length > 0) {
                  item.url.forEach((item) => {
                    var conf = item.split(":");
                    newObj[conf[0]] =
                      conf[0] === "size" ? conf[1] + "px" : conf[1];
                  });
                }
                setTittle003(newObj);
              }
              if (item.codeScreen === "TITTLE-004" && item.screenId === 4) {
                var newObj = tittle004;
                if (item.tooltip && item.tooltip !== "") {
                  newObj.text = item.tooltip;
                  newObj.default = false;
                }

                if (item.url.length > 0) {
                  item.url.forEach((item) => {
                    var conf = item.split(":");
                    newObj[conf[0]] =
                      conf[0] === "size" ? conf[1] + "px" : conf[1];
                  });
                }
                setTittle004(newObj);
              }

              if (item.codeScreen === "ELEMENT-001" && item.screenId === 4) {
                setUseInputAdornments(true);
              }

              if (item.codeScreen === "ELEMENT-002" && item.screenId === 4) {
                setUseNameInForm(false);
              }

              if (
                item.codeScreen === "USER-LASTNAME-LOGIN" &&
                item.screenId === 4
              ) {
                setUseLastName(true);
              }

              if (item.codeScreen === "ELEMENT-005" && item.screenId === 4) {
                setUpdateUserName(true);
              }

              if (
                item.codeScreen === "ENABLE-TWO-FACTOR-LOGIN" &&
                item.screenId === 4
              ) {
                setEnableTwoFactorLogin(true);
              }

              if (item.codeScreen === "LOGIN-ACCESS-LIMIT") {
                setLoginAccessLimit(parseInt(item.urlString));
              }

              if (item.codeScreen === "MODAL-ACHE") {
                setShowModalAche(true);
              }
            });
          }

          changeBackgroundImageMobile();

          const urlParams = new URLSearchParams(window.location.search);
          const token = urlParams.get("token");
          if (token) {
            initLoginSystem(token);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
          // Inicia o cache
          imageCacheInit();
        })
        .catch((err) => {
          setIsLoading(false);
          setAlertTitle(getDic("erro"));
          setAlertMessage(getDic("evento-nao-localizado"));
          setAlertOpen(true);
        });
    }, 1000); // 1 minuto = 60000
  };

  const checkIfLoginByNameAndEmailFromParams = () => {
    // A lógica de cadastro fica na tela de cadastro, caso esses dois parametros sejam passados
    // Redirecionar para a tela de cadastro e sguir a partir de lá
    if (
      window.location.href.includes("?") &&
      window.location.href.includes("name=") &&
      window.location.href.includes("email=") &&
      (eventId === "67710" || eventId === "73292")
    ) {
      const _params = window.location.href.substring(
        window.location.href.indexOf("?") + 1
      );
      navigationHistoryService.setHistory(
        props.history,
        "/" + eventId + "/registration?" + _params
      );
    }
  };

  const imageCacheInit = async () => {
    var configList = props.config;
    //Aqui, pega todas as configs que possua URL de imagem PNG ou JPG, etc...
    let allImages = configList.filter(
      (e) =>
        e.url.toString().toUpperCase().indexOf("JPG") > -1 ||
        e.url.toString().toUpperCase().indexOf("JPEG") > -1 ||
        e.url.toString().toUpperCase().indexOf("PNG") > -1 ||
        e.url.toString().toUpperCase().indexOf("WEBP") > -1
    );
    //Cria uma array e completa ela com todas as imagens vindas da API (configs)
    let _img = [];
    allImages.forEach((element) => {
      if (element.url.length == 1) {
        _img.push(element.url[0]);
      } else if (element.url.length && element.url.length > 1) {
        element.url.forEach((e) => {
          if (e.indexOf("{") > -1) {
            let _url = e.split("{")[1].replace("}", "");
            _img.push(_url);
          } else if (
            e.toUpperCase().indexOf("JPEG") > -1 ||
            e.toUpperCase().indexOf("JPG") > -1 ||
            e.toUpperCase().indexOf("PNG") > -1 ||
            e.toUpperCase().indexOf("WEBP") > -1
          ) {
            _img.push(e);
          }
        });
      }
    });
    preloadImages(_img, () => {
      //No callback podemos liberar o carregamento da página, depois de todas imagens carregadas.
      //Ou não
      console.log("All images were loaded");
    });
  };

  const preloadImages = (urls, allImagesLoadedCallback) => {
    var loadedCounter = 0;
    var toBeLoadedNumber = urls.length;
    //Pega cada URL de imagem, e monta em um novo element IMG
    urls.forEach(function (url) {
      preloadImage(url, function () {
        loadedCounter++;
        if (loadedCounter == toBeLoadedNumber) {
          allImagesLoadedCallback();
        }
      });
    });
    function preloadImage(url, anImageLoadedCallback) {
      var img = new Image();
      img.onload = anImageLoadedCallback;
      img.src = url;
      var elem = document.createElement("img");
      elem.setAttribute("src", url);
      elem.setAttribute("height", "0");
      elem.setAttribute("width", "0");
      elem.setAttribute("alt", "Flower");
      elem.setAttribute("hidden", true);
      document.getElementById("cache_img").appendChild(elem);
    }
  };

  const errorTreatment = (message, error) => {
    // Exibir alerta na tela caso o usuário seja admin
    console.log(message, error);
    if (isAdmin || isDeveloper) {
      alert(message);
    }
  };

  const onKeyPressHandler = (value) => {
    if (value === "Enter") {
      tryLogin();
    }
  };

  const initLoginSystem = async (token, _type) => {
    return getUserByEmailOrCpfOrInternalCode(
      eventId,
      store.getState().config,
      token,
      null,
      null,
      null,
      null,
      token,
      null
    )
      .then((data) => {
        let user = data.user;
        if (user.id > 0) {
          if (token) {
            loginUser(user);
            return user;
          } else {
            setOpenLoading(false);
            setAlertTitle("");
            setAlertMessage(getDic("primeiro-acesso-erro-mensagem"));
            setAlertOpen(true);
          }
        } else {
          setOpenLoading(false);
          setAlertTitle("");
          setAlertMessage(getDic("usuario-nao-localizado"));
          setAlertOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setOpenLoading(false);
        setAlertTitle("");
        setAlertMessage(getDic("usuario-nao-localizado"));
        setAlertOpen(true);
      });
  };

  const tryLogin = async () => {
    const { name, email, lastName } = accessForm;

    const _email = email ? email.trim() : "";
    var emailValidator = validateEmail(_email);
    if (safeLoginConfig.isSafePassword && !safeLoginConfig.emailValidator) {
      emailValidator = true;
    }

    const _termsNotRequired = store.getState().config.find((c) => {
      return c.codeScreen === "TERMS-NOT-REQUIRED";
    });

    if (
      (name === "" && useNameInForm) ||
      (lastName === "" && useLastName) ||
      _email === ""
    ) {
      setAlertTitle(getDic("atencao"));
      setAlertMessage(getDic("preenchimento-obrigatorio-mensagem"));
      setAlertOpen(true);
    } else if (
      (safeLoginConfig.isSafePassword ||
        hasPasswordUser ||
        loginByEmailAndInternalCode ||
        isAdmin ||
        isStaff) &&
      passwordUser === ""
    ) {
      setAlertTitle(getDic("atencao"));
      setAlertMessage(getDic("preenchimento-obrigatorio-mensagem"));
      setAlertOpen(true);
    } else if (!isCpf && !isInternalCode && !emailValidator) {
      setAlertTitle(getDic("atencao"));
      setAlertMessage(getDic("email-invalido"));
      setAlertOpen(true);
    } else if (useRecaptcha && !enableTwoFactorLogin && !recaptchaVerify) {
      setAlertTitle(getDic("atencao"));
      setAlertMessage(getDic("recaptcha-erro"));
      setAlertOpen(true);
    } else if (!_termsNotRequired && terms && !checkAuthorization) {
      setAlertTitle(getDic("atencao"));
      setAlertMessage(getDic("termos-necessita-aceite"));
      setAlertOpen(true);
    } else {
      setOpenLoading(true);

      try {
        await checkMaxNumberOfLogins();
      } catch (error) {
        return;
      }

      // Limpa espaços em branco
      const _newAccessForm = {
        name: accessForm.name.trim(),
        email: accessForm.email.trim(),
        lastName: accessForm.lastName.trim(),
      };
      setAccessForm(_newAccessForm);

      //Busca usuário na api e salva na store
      getUserByEmailOrCpfOrInternalCode(
        props.event.id,
        store.getState().config,
        _newAccessForm.email,
        isAdmin,
        isDeveloper,
        isStaff,
        safeLoginConfig.isSafePassword ||
          hasPasswordUser ||
          loginByEmailAndInternalCode ||
          isAdmin ||
          isStaff
          ? passwordUser
          : null,
        null,
        recaptchaVerify
      )
        .then(async (data) => {
          if (data.isTokenEmail) {
            setOpenModalTwoFactorLogin(true);
            setOpenLoading(false);
            return;
          }

          let user = data.user;
          if (user.id > 0) {
            checkIfHasRankingInteegraEventos(user);
            saveUserAccess(user.id, props.event.id, user.user_token);
            updateUserData(user);
          } else {
            setOpenLoading(false);
            setAlertTitle("");
            setAlertMessage(getDic("usuario-nao-localizado"));
            setAlertOpen(true);
            setRecaptchaVerify();
          }
        })
        .catch((err) => {
          console.log("Error", err.toString());
          let _message =
            hasPasswordUser || hasFirstAccessPolicy
              ? getDic("falha-login-mensagem")
              : getDic("usuario-nao-localizado");
          if (err && err.toString() === "Error: Email inválido") {
            _message = `${getDic("email")} ${getDic("invalido")}`;
          }
          if (err && err.toString() === "USER_NOT_FOUND_&_TYPE_2") {
            _message = `${getDic("email")} ${getDic("invalido")}`;
          }
          if (err && err.toString() === "USER_NOT_FOUND_&_TYPE_4") {
            _message = `${getDic("email")} ${getDic("ou")} ${getDic(
              "senha"
            )} ${getDic("invalidos")}`;
          }
          if (
            err &&
            (err.toString() === "USER_NOT_FOUND_&_TYPE_3" ||
              err.toString() === "USER_NOT_FOUND_&_TYPE_5" ||
              err.toString() === "USER_NOT_FOUND_&_TYPE_6" ||
              err.toString() === "USER_NOT_FOUND_&_TYPE_9")
          ) {
            _message = `${getDic("login")} ${getDic("ou")} ${getDic(
              "senha"
            )} ${getDic("invalidos")}`;
          }

          let _errorMessageConfig = store.getState().config.find((c) => {
            return c.codeScreen === "LOGIN-ERROR-MESSAGE";
          });
          if (_errorMessageConfig) {
            _message = _errorMessageConfig.tooltip;
          }

          setOpenLoading(false);
          setAlertTitle("");
          setAlertMessage(_message);
          setAlertOpen(true);
          setRecaptchaVerify();
        });
    }

    async function checkIfHasRankingInteegraEventos(user) {
      try {
        if (!user || !user.internalCode) {
          return;
        }

        let _useRankingInteegraEventos = store.getState().config.find((c) => {
          return c.codeScreen === "USE-RANKING-INTEEGRA-EVENTOS-PLUS";
        });

        if (
          !_useRankingInteegraEventos ||
          !_useRankingInteegraEventos.urlString ||
          !_useRankingInteegraEventos.tooltip
        ) {
          return;
        }

        await rankingService.setAdminPlusRankingPoints(
          _useRankingInteegraEventos.tooltip.trim(),
          false,
          user.internalCode,
          _useRankingInteegraEventos.urlString.trim()
        );
      } catch (error) {
        console.log("Error checkIfHasRankingInteegraEventos", error);
      }
    }

    async function checkMaxNumberOfLogins() {
      try {
        if (loginAccessLimit <= 0 || isAdmin || isStaff) {
          return;
        }

        let _count = await getCountOnlineGuests();
        if (_count.online >= loginAccessLimit) {
          throw null;
        }
      } catch (error) {
        console.log("Error checkMaxNumberOfLogins");
        setOpenLoading(false);
        setAlertTitle(getDic("erro"));
        setAlertMessage(
          !error ? getDic("capacidade-maxima-erro") : getDic("enviado-erro")
        );
        setAlertOpen(true);
        throw error;
      }
    }
  };

  const validateTokenTwoFactorLogin = () => {
    setOpenLoading(true);
    validateLoginByEventUserAndToken(
      eventId,
      accessForm.email,
      twoFactorLoginToken,
      recaptchaVerify
    )
      .then((user) => {
        updateUserData(user);
      })
      .catch((err) => {
        setAlertTitle(getDic("erro"));
        setAlertMessage(`Token ${getDic("invalido")}`);
        setAlertOpen(true);
        setOpenLoading(false);
      });
  };

  const updateUserData = async (user) => {
    var userRes = user;
    userRes.name =
      !userRes.name || userRes.name === "" ? accessForm.name : userRes.name;

    if (terms) {
      userRes.firstTerm = checkAuthorization;
      changeUserTerms(checkAuthorization, true)
        .then(() => {
          console.log("Usuario atualizado");
        })
        .catch((err) => {
          console.log("Erro changeUserTerms: ", err);
        });
    }

    if (updateUserName && useNameInForm) {
      try {
        let _attributes = await getAttributes(parseInt(eventId));
        let _nameAttribute = _attributes.find((a) => {
          return a.dbReference === "Nome";
        });

        if (_nameAttribute) {
          const attributesValueList = [];
          var field = createFormControlModel(
            _nameAttribute.id,
            null,
            `${accessForm.name.trim()} ${accessForm.lastName.trim()}`,
            "text",
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            "Nome"
          );
          attributesValueList.push(field);

          await updateUserApi(userRes, attributesValueList, isDeveloper);
          console.log("Participante atualizado");
          userRes.name = attributesValueList[0].value;
        }

        loginUser(userRes);
      } catch (error) {
        console.log("Erro updateUserApi", error);
        setIsLoading(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setAlertOpen(true);
      }
    } else {
      loginUser(userRes);
    }
  };

  const loginUser = async (user) => {
    await props.userLoginSuccess(user);

    // Verifica se o evento possui Google Analytics
    if (store.getState().event.hasGoogleAnalytics) {
      initGoogleAnalytics(store.getState().event.googleAnalyticsId);
    }

    //Verifica a programação de credenciamento e salva na api
    var schedule = store.getState().schedules.find((sched) => {
      return sched.title.toUpperCase() === "CREDENCIAMENTO";
    });

    if (schedule && schedule.id) {
      checkinService
        .insertNewCheckin(schedule.id)
        .then((check) => {
          saveUserAccess(user.id, props.event.id, user.user_token);
          navigatePage(user);
        })
        .catch((err) => {
          console.log("Erro insertNewCheckin: ", err);

          navigatePage(user);
        });
    } else {
      navigatePage(user);
    }
  };

  const navigatePage = (user) => {
    //Enviar dados de acesso
    forceSendAccessReport();

    var noBadge = store.getState().config.find((item) => {
      return item.codeScreen === "NO-BADGE";
    });
    var noTerms = store.getState().config.find((item) => {
      return item.codeScreen === "TERMS-NOT-REQUIRED";
    });
    if (!noTerms && user.firstTerm) {
      navigationHistoryService.setHistory(
        props.history,
        "/" + eventId + "/eventhall"
      );
    } else if (noBadge) {
      navigationHistoryService.setHistory(
        props.history,
        "/" + eventId + "/eventhall"
      );
    } else {
      //Navega para a página seguinte
      navigationHistoryService.setHistory(
        props.history,
        "/" + eventId + "/badge"
      );
    }
  };

  const onChangeHandler = (field, value) => {
    var _newAccessForm = accessForm;
    _newAccessForm[field] = value;
    setAccessForm(_newAccessForm);
    setState(state === true ? false : true);
  };

  const changeLanguage = async (lang) => {
    await props.setLanguage({ lang: lang });
    setLanguage(lang);

    setTimeout(() => {
      setLanguageTexts();
    }, 500);
  };

  const setLanguageTexts = () => {
    // Reseta textos com a linguagem correta
    if (primaryTitle.default) {
      setPrimaryTitle({
        ...primaryTitle,
        text: `${getDic("bem-vindo").toUpperCase()} ${getDic(
          "ao"
        ).toUpperCase()} ${getDic("nosso").toUpperCase()} ${getDic(
          "evento"
        ).toUpperCase()}`,
      });
    }

    if (secondaryTitle.default) {
      setSecondaryTitle({
        ...secondaryTitle,
        text: getDic("bem-vindo-mensagem").toUpperCase(),
      });
    }

    if (button1.default) {
      setButton1({
        ...button1,
        text: getDic("acessar"),
      });
    }

    if (button2.default) {
      setButton2({
        ...button2,
        text: getDic("nao-possui-cadastro"),
      });
    }

    if (button3.default) {
      setButton3({
        ...button3,
        text: getDic("primeiro") + " " + getDic("acesso") + "?",
      });
    }

    if (button4.default) {
      setButton4({
        ...button4,
        text: getDic("esqueceu-a-senha"),
      });
    }

    if (tittle003.default) {
      setTittle003({
        ...tittle003,
        text: getDic("ler") + " " + getDic("termos"),
      });
    }

    if (tittle004.default) {
      setTittle004({
        ...tittle004,
        text: getDic("termos-mensagem"),
      });
    }
  };

  const navigateToRegistrationPage = () => {
    navigationHistoryService.setHistory(
      props.history,
      "/" + eventId + "/registration"
    );
  };

  const forgotPasswordRoutine = async () => {
    try {
      if (!forgotPasswordObject.login) {
        setAlertTitle(getDic("atencao"));
        setAlertMessage(getDic("preenchimento-obrigatorio-mensagem"));
        setAlertOpen(true);
        return;
      }
      if (
        dialogForgotPasswordControl.showInsertToken &&
        (!forgotPasswordObject.newPassword1 ||
          !forgotPasswordObject.newPassword2 ||
          !forgotPasswordObject.token)
      ) {
        setAlertTitle(getDic("atencao"));
        setAlertMessage(getDic("preenchimento-obrigatorio-mensagem"));
        setAlertOpen(true);
        return;
      }
      if (
        dialogForgotPasswordControl.showInsertToken &&
        forgotPasswordObject.newPassword1 !== forgotPasswordObject.newPassword2
      ) {
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("senha-confirmacao-erro"));
        setAlertOpen(true);
        return;
      }

      setDialogForgotPasswordControl({
        ...dialogForgotPasswordControl,
        waiting: true,
      });

      let _ret = await setForgotPasswordRoutine(
        eventId,
        props.event.name,
        forgotPasswordObject.login,
        forgotPasswordObject.token,
        forgotPasswordObject.newPassword1,
        forgotPasswordObject.newPassword2
      );

      if (_ret.emailSent) {
        setDialogForgotPasswordControl({
          ...dialogForgotPasswordControl,
          waiting: false,
          showInsertToken: true,
        });
        return;
      }

      setDialogForgotPasswordControl({
        ...dialogForgotPasswordControl,
        open: false,
      });

      loginUser(_ret.user);
    } catch (error) {
      console.log("Erro forgotPasswordRoutine", error);
      setAlertTitle(getDic("erro"));
      setAlertMessage(getDic("enviado-erro"));
      setAlertOpen(true);
    }
  };

  const openSsoMicrosoftLoginPopup = React.useCallback(async () => {
    try {
      setOpenLoading(true);
      let _user = await ssoMicrosoftService.openLoginPopup();
      await loginUser(_user);
    } catch (error) {
      console.log("Error openSsoMicrosoftLoginPopup");
    } finally {
      setOpenLoading(false);
    }
  });

  return (
    <LoginPageContext.Provider
      value={{
        state: {
          state: state,
          setState,
          eventId: eventId,
          setEventId,
          isAdmin: isAdmin,
          setIsAdmin,
          isStaff: isStaff,
          setIsStaff,
          isDeveloper: isDeveloper,
          setIsDeveloper,
          blockAccess: blockAccess,
          setBlockAccess,
          hasEvent: hasEvent,
          setHasEvent,
          hasLang: hasLang,
          setHasLang,
          accessForm: accessForm,
          setAccessForm,
          useRecaptcha: useRecaptcha,
          setUseRecaptcha,
          recaptchaVerify: recaptchaVerify,
          setRecaptchaVerify,
          loadingMessage: loadingMessage,
          setLoadingMessage,
          openLoading: openLoading,
          setOpenLoading,
          hasPasswordUser: hasPasswordUser,
          setHasPasswordUser,
          passwordUser: passwordUser,
          setPasswordUser,
          hasFirstAccessPolicy: hasFirstAccessPolicy,
          setHasFirstAccessPolicy,
          hasRecoverPassword: hasRecoverPassword,
          setHasRecoverPassword,
          dialogForgotPasswordControl: dialogForgotPasswordControl,
          setDialogForgotPasswordControl,
          forgotPasswordObject: forgotPasswordObject,
          setForgotPasswordObject,
          isForgotPassword: isForgotPassword,
          setIsForgotPassword,
          safeLoginConfig: safeLoginConfig,
          setSafeLoginConfig,
          image001: image001,
          setImage001,
          image003: image003,
          setImage003,
          isLoading: isLoading,
          setIsLoading,
          language: language,
          setLanguage,
          languages: languages,
          setLanguages,
          alertOpen: alertOpen,
          setAlertOpen,
          alertTitle: alertTitle,
          setAlertTitle,
          alertMessage: alertMessage,
          setAlertMessage,
          imageBackgroundUrl: imageBackgroundUrl,
          setImageBackgroundUrl,
          colorBackGround: colorBackGround,
          setColorBackGround,
          primaryTitle: primaryTitle,
          setPrimaryTitle,
          secondaryTitle: secondaryTitle,
          setSecondaryTitle,
          isCpf: isCpf,
          setIsCpf,
          isInternalCode: isInternalCode,
          setIsInternalCode,
          internalCodeText: internalCodeText,
          setInternalCodeText,
          hasRegister: hasRegister,
          setHasRegister,
          cardConfig: cardConfig,
          setCardConfig,
          button1: button1,
          setButton1,
          button2: button2,
          setButton2,
          button3: button3,
          setButton3,
          button4: button4,
          setButton4,
          button5: button5,
          setButton5,
          checkAuthorization: checkAuthorization,
          setCheckAuthorization,
          terms: terms,
          setTerms,
          isComboboxTerms: isComboboxTerms,
          setIsComboboxTerms,
          isIframeTerms: isIframeTerms,
          setIsIframeTerms,
          autoOpenTerms: autoOpenTerms,
          setAutoOpenTerms,
          openModalTerms: openModalTerms,
          setOpenModalTerms,
          termsCheckboxDisabled: termsCheckboxDisabled,
          setTermsCheckboxDisabled,
          tittle004: tittle004,
          setTittle004,
          tittle003: tittle003,
          setTittle003,
          useInputAdornments: useInputAdornments,
          setUseInputAdornments,
          useNameInForm: useNameInForm,
          setUseNameInForm,
          useLastName: useLastName,
          setUseLastName,
          updateUserName: updateUserName,
          setUpdateUserName,
          loginByEmailAndInternalCode: loginByEmailAndInternalCode,
          setLoginByEmailAndInternalCode,
          useInternalCodeHasPassword: useInternalCodeHasPassword,
          setUseInternalCodeHasPassword,
          google: google,
          setGoogle,
          facebook: facebook,
          setMicrosoftSso,
          microsoftSso: microsoftSso,
          setFacebook,
          enableTwoFactorLogin: enableTwoFactorLogin,
          setEnableTwoFactorLogin,
          userObjectTwoFactorLogin: userObjectTwoFactorLogin,
          setUserObjectTwoFactorLogin,
          openModalTwoFactorLogin: openModalTwoFactorLogin,
          setOpenModalTwoFactorLogin,
          twoFactorLoginToken: twoFactorLoginToken,
          setTwoFactorLoginToken,
          openModalAche: openModalAche,
          setOpenModalAche,
          showModalAche: showModalAche,
          setShowModalAche,
        },
        functions: {
          getPageData,
          imageCacheInit,
          preloadImages,
          errorTreatment,
          onKeyPressHandler,
          initLoginSystem,
          tryLogin,
          validateTokenTwoFactorLogin,
          updateUserData,
          loginUser,
          navigatePage,
          onChangeHandler,
          changeLanguage,
          navigateToRegistrationPage,
          forgotPasswordRoutine,
          openSsoMicrosoftLoginPopup,
        },
      }}
    >
      {props.children}
    </LoginPageContext.Provider>
  );
};

function mapStateToProps(state) {
  return {
    event: state.event,
    lang: state.lang,
    config: state.config,
    user: state.user,
  };
}

const mapDispatchToProps = {
  userLoginSuccess,
  setLanguage,
  resetAllAccess,
  resetNotifications,
  userLogout,
  resetChosenPavilion,
  resetPrivateChatControl,
  resetHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageProvider);
